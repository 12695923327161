
<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M6.25 4.75H4.75C3.92157 4.75 3.25 5.42157 3.25 6.25V7.75M13.75 4.75H15.25C16.0784 4.75 16.75 5.42157 16.75 6.25V7.75M16.75 12.25V13.75C16.75 14.5784 16.0784 15.25 15.25 15.25H13.75M6.25 15.25H4.75C3.92157 15.25 3.25 14.5784 3.25 13.75V12.25M7 8.5V11.5M13 8.5V11.5M10 8.5V10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CustomMetricIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
