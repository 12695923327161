<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="5"
      r="1.5"
      fill="currentColor"
    />
    <circle
      cx="10"
      cy="15"
      r="1.5"
      fill="currentColor"
    />
    <path
      d="M4 10H16"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DivideIcon',
  props: {
    width: { type: Number, default: 20 },
    height: { type: Number, default: 20 }
  }
}
</script>

<style scoped></style>
