<template>
  <div class="relative">
    <!-- Full screen loader -->
    <div
      v-if="loading.delete"
      class="bg-background-pressed absolute top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-opacity-75 rounded-md"
    >
      <BaseLoadingSpinnerCircle
        class="text-text-muted"
        width="20"
        height="20"
      />
    </div>
    <!-- Header -->
    <div class="p-5 flex items-center justify-between w-full">
      <div class="flex items-center gap-2">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          <span>{{ currentShare.name }}</span>
        </BaseText>
        <!-- Published status -->
        <div
          v-if="editForm?.isPublished"
          class="flex items-center rounded-md gap-2 py-0.5 pl-2 pr-1.5 bg-secondary-green-10"
        >
          <div class="bg-secondary-green-200 rounded-full w-1 h-1" />
          <BaseText
            class="text-secondary-green-200"
            type="label"
            size="xs"
          >
            Published
          </BaseText>
        </div>
        <div
          v-else
          class="flex items-center rounded-md gap-2 py-0.5 pl-2 pr-1.5 bg-background-normal"
        >
          <div class="bg-icon-disabled rounded-full w-1 h-1" />
          <BaseText
            class="text-icon-normal"
            type="label"
            size="xs"
          >
            Not Published
          </BaseText>
        </div>
      </div>
      <div class="flex gap-2 items-center">
        <button
          class="black-btn-primary py-1 px-3 rounded-md flex items-center justify-center text-white w-20"
          :disabled="!isUpdateValid"
          @click="handleEditUpdate"
        >
          <BaseLoadingSpinnerCircle
            v-if="loading?.edit"
          />
          <BaseText
            v-else
            type="label"
            size="sm"
          >
            Update
          </BaseText>
        </button>
        <div
          v-on-clickaway="() => { show.optionsDropdown = false }"
          class="relative"
        >
          <button
            class="base-btn h-8 hover:bg-background-normal rounded-md transition-colors"
            :class="{'bg-background-normal' : show.optionsDropdown}"
            style="padding: 6px;"
            @click="show.optionsDropdown = !show.optionsDropdown"
          >
            <KebabMenuIcon class="text-icon-normal" />
          </button>

          <transition name="fade">
            <div
              v-if="show.optionsDropdown"
              class="p-1 absolute right-0 mt-1 border border-border-normal bg-white overflow-y-hidden dropdown-scrollable shadow-md rounded-lg "
              style="z-index: 99999"
            >
              <!--  -->
              <button
                v-if="editForm?.isPublished"
                class="group cursor-pointer flex items-center gap-2 rounded-lg p-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap w-full"
                @click="updateEditForm('isPublished', false)"
              >
                <UnPublishIcon class="group-hover:text-text-muted text-icon-normal" />
                <BaseText
                  type="label"
                  size="sm"
                  class="group-hover:text-text-muted text-icon-normal"
                >
                  UnPublish
                </BaseText>
              </button>
              <button
                v-else
                class="group cursor-pointer flex items-center gap-2 rounded-lg p-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap w-full"
                @click="updateEditForm('isPublished', true)"
              >
                <PlusIcon class="group-hover:text-text-muted text-icon-normal" />
                <BaseText
                  type="label"
                  size="sm"
                  class="group-hover:text-text-muted text-icon-normal"
                >
                  Publish
                </BaseText>
              </button>
              <button
                class="group cursor-pointer flex items-center gap-2 rounded-lg p-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap w-full"
                @click="handleShareDelete"
              >
                <DeleteIcon class="group-hover:text-text-muted text-icon-normal" />
                <BaseText
                  type="label"
                  size="sm"
                  class="group-hover:text-text-muted text-icon-normal"
                >
                  Delete
                </BaseText>
              </button>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- Type selection -->

    <div
      class="grid grid-cols-6 px-5 py-3"
    >
      <div class="col-span-3 space-y-1">
        <BaseText
          size="sm"
          class="text-icon-normal"
        >
          Created by
        </BaseText>
        <div class="flex w-full items-center gap-2">
          <img
            :src="currentShare.created_by?.avatar"
            class=" w-5 h-5 rounded-full"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            {{ currentShare.created_by?.name }}
          </BaseText>
        </div>
        <!--  -->
      </div>
      <div class="col-span-3 space-y-1">
        <BaseText
          size="sm"
          class="text-icon-normal"
        >
          Views
        </BaseText>
        <BaseText
          type="label"
          class="text-text-muted"
        >
          {{ currentShare?.view_count }}
        </BaseText>
      </div>
    </div>
    <div class="p-5 space-y-3 w-full">
      <div>
        <!-- Share link -->
        <div class="flex gap-3 w-full h-8">
          <input
            :value="getShareLink"
            disabled
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
          <button
            v-clipboard:copy="getShareLink"
            v-clipboard:success="handleClipboardCopy"
            class="flex-shrink-0 text-white black-btn-primary flex items-center gap-1 rounded-md px-3 py-1.5"
          >
            <LinkIcon />
            <BaseText
              type="label"
              size="sm"
            >
              Copy Link
            </BaseText>
          </button>
        </div>
      </div>
      <!-- Name -->
      <div class="flex flex-col gap-1 w-full">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Report Name
        </BaseText>

        <input
          :value="currentShare.name"
          disabled
          placeholder="Name"
          class="form-input fade-in min-w-0 w-full px-2 py-1.5"
        >
      </div>
      <!-- Description -->
      <div class="space-y-2">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Description
        </BaseText>
        <input
          :value="currentShare.description || 'N/A'"
          disabled
          placeholder="Type a description of this report"
          class="form-input fade-in min-w-0 w-full px-2 py-1.5"
        >
      </div>
      <!-- Divider -->
      <div>
        <div class="w-full border-t border-border-normal my-5" />
      </div>
      <!-- Security section -->
      <div>
        <BaseText
          class="uppercase text-icon-normal mb-2.5"
          type="heading"
          size="overline"
        >
          Report Security
        </BaseText>
        <div class="flex flex-col gap-2.5">
          <!-- Password -->
          <div class="flex items-center justify-between">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Password
            </BaseText>
            <BaseToggle
              :value="editForm.showPassword"
              @toggle="updateEditForm('showPassword', !editForm.showPassword)"
            />
          </div>
          <div
            v-if="editForm.showPassword"
            class="relative flex items-center gap-2"
          >
            <input
              ref="passwordForm"
              v-model="editForm.password"
              :disabled="!canEditPassword"
              placeholder="Password"
              class="form-input fade-in min-w-0 w-full px-2 py-1.5"
            >
            <button
              class="base-btn p-1.5 rounded-md group"
              @click="handlePasswordEdit"
            >
              <EditIcon
                v-if="!canEditPassword"
                class="text-icon-disabled group-hover:text-icon-normal"
              />
              <TagRemoveIcon
                v-else
                class="text-icon-disabled group-hover:text-icon-normal"
              />
            </button>
          </div>
          <div class="space-y-1 relative">
            <!-- Coming soon -->
            <div class="bg-background-disabled rounded-md absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 opacity-80">
              <BaseText class="text-text-muted">
                Coming Soon
              </BaseText>
            </div>
            <!-- Tracking -->
            <div class="flex items-center justify-between">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Track Opens & IPs
              </BaseText>
              <BaseToggle
                :value="editForm.trackIps"
                @toggle="updateEditForm('trackIps', !editForm.trackIps)"
              />
            </div>
            <div
              class="border border-border-normal rounded-md relative"
            >
              <!-- <div
                v-if="!editForm.trackIps"
                class="absolute top-0 left-0 w-full h-full bg-background-disabled opacity-75 flex items-center justify-center"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-subdued"
                >
                  Not tracking
                </BaseText>
              </div> -->
              <!-- Table header -->
              <div class="grid grid-cols-12 uppercase text-text-subdued">
                <BaseText
                  type="heading"
                  size="overline"
                  class="col-span-4 p-3"
                >
                  Opened
                </BaseText>
                <BaseText
                  type="heading"
                  size="overline"
                  class="col-span-4 p-3"
                >
                  IP
                </BaseText>
                <BaseText
                  type="heading"
                  size="overline"
                  class="col-span-4 p-3"
                >
                  Location
                </BaseText>
              </div>
              <!-- Table data -->
              <div class="grid grid-cols-12">
                <!--  -->
              </div>
            </div>
            <!-- Comments -->
            <div class="flex items-center justify-between">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Show Comments
              </BaseText>
              <BaseToggle
                :value="editForm.showComments"
                @toggle="updateEditForm('showComments', !editForm.showComments)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import _ from 'lodash'
import LensAPI from '@/api/lens'
// Components
import DateRangeSelectorDropdown from '../../../search/TopBarComponents/DateRangeSelectorDropdown.vue'
// Icons
import LinkIcon from '../../../globals/Icons/LinkIcon.vue'
import UpdatingReportIcon from '../../../globals/Icons/LensIcons/report/UpdatingReportIcon.vue'
import StaticReportIcon from '../../../globals/Icons/LensIcons/report/StaticReportIcon.vue'
import CalendarIcon from '../../../globals/Icons/CalendarIcon.vue'
import DeleteIcon from '../../../globals/Icons/DeleteIcon.vue'
import EditIcon from '../../../globals/Icons/EditIcon.vue'
import KebabMenuIcon from '../../../globals/Icons/KebabMenuIcon.vue'
import UnPublishIcon from '../../../globals/Icons/UnPublishIcon.vue'
import PlusIcon from '../../../globals/Icons/PlusIcon.vue'
import TagRemoveIcon from '../../../globals/Icons/TagRemoveIcon.vue'
export default {
  name: 'ShareReportForm',
  components: {
    DateRangeSelectorDropdown,
    // Icons
    LinkIcon,
    DeleteIcon,
    EditIcon,
    UnPublishIcon,
    PlusIcon,
    TagRemoveIcon,
    KebabMenuIcon,
    UpdatingReportIcon,
    StaticReportIcon,
    CalendarIcon
  },
  mixins: [clickaway],
  props: {
    currentShare: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      editKeyMapping: {
        isPublished: 'is_published',
        showPassword: 'is_password_locked',
        trackIps: 'is_tracking',
        showComments: 'is_comments_visible',
        password: 'access_password'
      },

      canEditPassword: false,

      show: {
        optionsDropdown: false
      },

      originalForm: {
        password: '',
        showPassword: false,
        isPublished: false,
        trackIps: false,
        showComments: false
      },
      editForm: {
        password: '',
        showPassword: false,
        isPublished: false,
        trackIps: false,
        showComments: false
      }
    }
  },
  computed: {
    isUpdateValid () {
      const keys = Object.keys(this.originalForm)
      return keys.some((key) => {
        if (key === 'password' || key === 'showPassword') {
          return (this.originalForm.showPassword && !this.editForm.showPassword) || (this.editForm.showPassword && this.editForm.password.length > 0 && this.editForm.password !== this.originalForm.password)
        }
        return this.originalForm[key] !== this.editForm[key]
      })
    },
    getShareLink () {
      return (
        process.env.VUE_APP_BASE_URL +
        '/share/report/' +
        encodeURIComponent(this.currentShare.id)
      )
    }
  },
  watch: {
    currentShare: {
      handler (share) {
        if (share) {
          this.editForm = {
            password: share.is_password_locked ? '***************' : '',
            showPassword: share.is_password_locked,
            isPublished: share.is_published,
            trackIps: share.is_tracking,
            showComments: share.is_comments_visible
          }
          this.originalForm = _.clone(this.editForm)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    console.log('mounted!')
  },
  methods: {
    handleShareDelete () {
      this.$emit('delete', this.currentShare.id)
      this.show.optionsDropdown = false
    },
    handlePasswordEdit () {
      this.editForm.password = !this.canEditPassword ? '' : this.originalForm.password
      this.canEditPassword = !this.canEditPassword
      if (this.canEditPassword) {
        this.$nextTick(() => {
          this.$refs.passwordForm.focus()
        })
      }
    },
    async handleEditUpdate () {
      const changedPayload = Object.keys(this.editForm)
        .filter(key => this.originalForm[key] !== this.editForm[key])
        .reduce((acc, key) => {
          const mappedKey = this.editKeyMapping[key]
          acc[mappedKey] = this.editForm[key]
          return acc
        }, {})
      this.$emit('update', this.currentShare.id, changedPayload)
      this.canEditPassword = false
    },
    handleClipboardCopy () {
      this.$showAlert({
        type: 'success',
        message: 'Copied to clipboard'
      })
    },
    updateShareData: _.debounce(async function (id, payload) {
      await LensAPI.LensReport.updateShare(id, payload)
    }, 100),
    async updateEditForm (key, value) {
      if (key === 'isPublished') {
        this.show.optionsDropdown = false
      }
      this.editForm[key] = value
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
  height: 32px;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}
.form-input:disabled{
  color: #A4ACB9;
  background-color: white;
  font-size: 14px;
}
.date-selector-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 500;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}

.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
</style>
