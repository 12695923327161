<template>
  <div class="mb-10">
    <div class="flex items-center gap-1 mb-2.5">
      <MagicBoxIcon class="text-icon-muted" />
      <BaseText
        id="custommetrics"
        ref="custommetrics"
        class="text-text-muted metric-section"
        type="heading"
        size="sm"
      >
        Custom Metrics
      </BaseText>
    </div>
    <div class="banner px-5 flex items-center justify-between">
      <div class="flex flex-col gap-2">
        <BaseText
          size="sm"
          class="text-neutral-alpha-650"
        >
          Coming Soon
        </BaseText>
        <div class="flex items-center gap-1 text-white">
          <CustomMetricIcon />
          <BaseText
            type="label"
            size="sm"
          >
            Custom Metric Builder
          </BaseText>
        </div>
      </div>
      <div>
        <img
          src="../../../assets/images/custom-metric-display.svg"
        >
      </div>
    </div>
  </div>
</template>

<script>
import CustomMetricIcon from '../../globals/Icons/LensIcons/MetricIcons/CustomMetricIcon.vue'
import MagicBoxIcon from '../../globals/Icons/LensIcons/MetricIcons/MagicBoxIcon.vue'
export default {
  name: 'CustomMetricSoonBanner',
  components: {
    CustomMetricIcon,
    MagicBoxIcon
  }
}
</script>

<style scoped>
.banner{
  border-radius: 12px;
  background-color:rgba(6, 7, 15, 0.92);
}
</style>
