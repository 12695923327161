<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      id="Vector"
      d="M7.75 7.75V4.1875C7.75 3.66973 8.16973 3.25 8.6875 3.25H15.8125C16.3303 3.25 16.75 3.66973 16.75 4.1875V11.3125C16.75 11.8303 16.3303 12.25 15.8125 12.25H12.25M11.3125 7.75H4.1875C3.66973 7.75 3.25 8.16973 3.25 8.6875V15.8125C3.25 16.3303 3.66973 16.75 4.1875 16.75H11.3125C11.8303 16.75 12.25 16.3303 12.25 15.8125V8.6875C12.25 8.16973 11.8303 7.75 11.3125 7.75Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DuplicateIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
