<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1665 2.29166V3.74999M14.0889 4.24437L12.9967 5.33659M5.33634 12.9968L4.24413 14.089M3.74984 9.16666H2.2915M5.33634 5.3365L4.24413 4.24428M11.7767 17.4991L8.6541 8.99864C8.52998 8.66074 8.86386 8.33505 9.19857 8.46754L17.5329 11.7665C17.8567 11.8947 17.8897 12.3401 17.5883 12.5146L14.471 14.3193C14.408 14.3558 14.3556 14.4081 14.3192 14.4711L12.5284 17.5642C12.3515 17.8698 11.8985 17.8306 11.7767 17.4991Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ConversionIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
