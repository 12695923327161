<template>
  <div>
    <!-- Header -->
    <div class="p-5 flex items-center justify-between w-full">
      <div class="flex items-center gap-2">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          <span> New Share Link</span>
        </BaseText>
      </div>
      <button
        class="black-btn-primary py-1.5 px-3 w-36 rounded-md flex items-center justify-center text-white"
        :disabled="!isFormValid"
        @click="$emit('createShare')"
      >
        <BaseLoadingSpinnerCircle
          v-if="loading.createShare"
        />
        <BaseText
          v-else
          type="label"
          size="sm"
          class="whitespace-nowrap"
        >
          Create Share Link
        </BaseText>
      </button>
    </div>
    <!-- Type selection -->
    <div
      class="flex justify-center items-center gap-3 px-5"
    >
      <div
        v-for="(shareType) in shareLinkTypes"
        :key="shareType.id"
        class="rounded-lg border p-3 group  transition-colors cursor-pointer space-y-2 w-full h-28"
        :class="getShareSelectClass(shareType.id)"
        @click="updateShareForm('type', shareType.id)"
      >
        <div class="flex items-center gap-2">
          <component
            :is="shareType.icon"
            class="text-icon-normal group-hover:text-icon-muted"
          />
          <BaseText
            class="text-text-normal group-hover:text-text-muted"
            type="label"
            size="sm"
          >
            {{ shareType.name }}
          </BaseText>
        </div>

        <BaseText
          class="text-text-normal group-hover:text-text-muted"
          size="sm"
        >
          {{ shareType.description }}
        </BaseText>
      </div>
    </div>

    <div class="p-5 space-y-3 w-full">
      <div>
        <BaseSingleDropdown
          v-if="shareForm.type === 'updating'"
          label="Live Date Range"
          placeholder="Select a rolling period"
          class="z-30 relative"
          use-parent-width
          show-label-space
          :selected-obj="shareForm.dateRange"
          :options="[]"
          @change="($event) => updateShareForm('dateRange', $event)"
        />
        <div
          v-else
          class="flex flex-col gap-1 items-start w-full relative"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Static Date Range
          </BaseText>
          <div
            class="form-input w-full flex items-center gap-2 py-1.5 px-2"
          >
            <CalendarIcon class="text-icon-disabled" />
            <BaseText
              class="text-text-disabled"
              size="sm"
            >
              {{ formatDateRange }}
            </BaseText>
          </div>
        </div>
      </div>

      <!-- Name -->
      <div class="flex flex-col gap-1 w-full">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Report Name
        </BaseText>

        <input
          v-model="shareForm.name"
          disabled
          placeholder="Name"
          class="form-input fade-in min-w-0 w-full px-2 py-1.5"
        >
      </div>
      <!-- Description -->
      <div class="space-y-1">
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Description
        </BaseText>
        <input
          :value="shareForm?.description || 'N/A'"
          disabled
          placeholder="Type a description of this report"
          class="form-input fade-in min-w-0 w-full px-2 py-1.5"
        >
      </div>
      <!-- Divider -->
      <div>
        <div class="w-full border-t border-border-normal my-5" />
      </div>
      <!-- Security section -->
      <div>
        <BaseText
          class="uppercase text-icon-normal mb-2.5"
          type="heading"
          size="overline"
        >
          Report Security
        </BaseText>
        <div class="flex flex-col gap-2.5">
          <!-- Password -->
          <div class="flex items-center justify-between">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Password
            </BaseText>
            <BaseToggle
              :value="enableForm.password"
              @toggle="updateSecurityConfig('password')"
            />
          </div>
          <div
            v-if="enableForm.password"
            class="relative"
          >
            <input
              v-model="shareForm.password"
              placeholder="Password"
              class="form-input fade-in min-w-0 w-full px-2 py-1.5"
            >
          </div>
          <!-- Tracking -->
          <div class="flex items-center justify-between opacity-60 cursor-not-allowed">
            <div class="flex items-center gap-1">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Track Opens & IPs
              </BaseText>
              <BaseText
                size="xs"
                class="text-text-subdued"
              >
                (Coming soon)
              </BaseText>
            </div>
            <BaseToggle
              :value="enableForm.trackIps"
              @toggle="updateSecurityConfig('trackIps')"
            />
          </div>
          <div
            v-show="enableForm.trackIps"
            class="relative"
          >
            <!-- TODO @Sam: table -->
          </div>
          <!-- Comments -->
          <div class="flex items-center justify-between opacity-60 cursor-not-allowed">
            <div class="flex items-center gap-1">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Show Comments
              </BaseText>
              <BaseText
                size="xs"
                class="text-text-subdued"
              >
                (Coming soon)
              </BaseText>
            </div>
            <BaseToggle
              :value="enableForm.showComments"
              @toggle="updateSecurityConfig('showComments')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
// Components
import DateRangeSelectorDropdown from '../../../search/TopBarComponents/DateRangeSelectorDropdown.vue'
// Icons
import LinkIcon from '../../../globals/Icons/LinkIcon.vue'
import UpdatingReportIcon from '../../../globals/Icons/LensIcons/report/UpdatingReportIcon.vue'
import StaticReportIcon from '../../../globals/Icons/LensIcons/report/StaticReportIcon.vue'
import CalendarIcon from '../../../globals/Icons/CalendarIcon.vue'
export default {
  name: 'ShareReportForm',
  components: {
    DateRangeSelectorDropdown,
    // Icons
    LinkIcon,
    UpdatingReportIcon,
    StaticReportIcon,
    CalendarIcon
  },
  mixins: [clickaway],
  props: {
    shareForm: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Object,
      default: () => {}
    },
    enableForm: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const shareLinkTypes = [
      {
        id: 'updating',
        name: 'Updating',
        icon: UpdatingReportIcon,
        description: 'Create a share link that updates with a rolling date range.'
      },
      {
        id: 'static',
        name: 'Static',
        icon: StaticReportIcon,
        description: 'Create a share link with a static date range and no updates.'
      }
    ]

    const securityToggles = [
      {
        id: 'password',
        name: 'Password'
      },
      {
        id: 'trackIps',
        name: 'Track Opens & IPs'
      },
      {
        id: 'showComments',
        name: 'Show Comments'
      }
    ]
    return {
      shareLinkTypes,
      securityToggles,

      showDateRangeSelector: false,
      dateFilterLabel: ''

    }
  },
  computed: {
    isFormValid () {
      const validPassword = this.enableForm.password ? this.shareForm.password.length > 0 : true
      return this.shareForm.type && this.shareForm.name && !this.loading.createShare && validPassword
    },
    formatDateRange () {
      if (!this.shareForm.dateRange?.start || !this.shareForm.dateRange?.end) {
        return 'All Time'
      }
      const options = { year: 'numeric', month: 'short', day: '2-digit' }
      const startDate = new Date(this.shareForm.dateRange.start).toLocaleDateString('en-US', options)
      const endDate = new Date(this.shareForm.dateRange.end).toLocaleDateString('en-US', options)
      return `${startDate} to ${endDate}`
    }
  },
  mounted () {
    console.log('mounted!')
  },
  methods: {
    getShareSelectClass (shareId) {
      const borderStyle = shareId === this.shareForm.type ? 'border-neutral-600' : 'border-border-normal'
      // TODO @Sam: remove when implementing updating
      const isUpdating = shareId === 'updating'
      const disableStyle = isUpdating ? 'opacity-75 cursor-not-allowed' : ''
      return `${isUpdating ? '' : borderStyle} ${disableStyle}`
    },
    handleClipboardCopy () {
      this.$showAlert({
        type: 'success',
        message: 'Copied to clipboard'
      })
    },
    updateShareForm (type, value) {
      if (type === 'dateRange') this.showDateRangeSelector = false
      if (type === 'type' && value === 'updating') return
      console.log(type, value)
      this.$emit('formChange', { type, value })
    },
    updateSecurityConfig (key) {
      if (key === 'showComments' || key === 'trackIps') return
      this.$emit('configChange', { type: key, value: !this.enableForm[key] })
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
  height: 32px;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}
.form-input:disabled{
  color: #A4ACB9;
  background-color: white;
  font-size: 14px;
}
.date-selector-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 500;
}

</style>
