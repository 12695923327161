<template>
  <MainLayout
    v-scroll="onScroll"
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <LensReportHeader
        :report-id="report?.id ?? $route.params.reportId"
        :title="report?.name"
        :description="report?.description"
        :loading="loading.report"
        :attribution-window="{click: report?.click_attribution_window, view: report?.view_attribution_window}"
        :lens="lens"
        @showDuplicateReport="modal.showDuplicateReport = true"
        @showShareReport="modal.showShareReport = true"
        @changeWindow="handleReportChange"
        @localReportUpdate="handleLocalReportChange"
      />
    </template>
    <template #filter>
      <div
        ref="filterBar"
        class="border border-border-normal bg-white"
      >
        <LensFilterToolbar
          :loading="loading.report"
          :group-by="report?.group_by"
          :report-type="reportType"
          :total-groups="totalComparaitiveGroups"
          :applied-filters.sync="appliedFilters"
          :selected-date-range.sync="selectedDateRange"
          :is-group-builder-open="!!showGroupBuilder"
          @toggleComparativeBuilder="showGroupBuilder = $event ?? !showGroupBuilder"
          @update:groupBy="handleReportChange"
          @fetchGroups="fetchAdGroups($event)"
        />
      </div>
      <div
        v-if="reportType === 'comparaitive' && showGroupBuilder"
        class="border border-border-normal rounded-md mt-4 mx-3"
      >
        <ComparaitiveGroupBuilder
          :comparaitive-query.sync="comparaitiveQuery"
          :comparaitive-info="comparaitiveInfo"
          @showBreakdown="setBreakdownData"
        />
      </div>
    </template>
    <template #content>
      <div class="bg-white">
        <div class="px-3 pt-4">
          <LensReportGraph
            :graph-type.sync="graphType"
            :data-items="graphDataItems"
            :data-summary="graphDataSummary"
            :graph-info="graphInfo"
            :result-count="tableResults?.ad_groups?.length"
            :has-mounted="!hasMounted"
            :selected-kpis="selectedColumns[graphType]"
            :can-add-kpis="!isSelectedColsMaxed"
            :loading-data="loading?.adGroups || loading?.report"
            @updateSelectedKpis="updateSelectedColumns"
            @showGroupBreakdown="handleShowGroupBreakdown"
          />
        </div>
        <div class="px-3 pt-3 relative">
          <!-- Table settings btn -->
          <LensTableOptions
            :loading="loading?.report"
            :table-pagination="getPagination"
            :table-columns="report?.table_columns"
            :table-config="report?.table_config"
            @paginationChanged="handlePaginationChange"
            @reportChanged="handleReportChange"
          />
          <Transition
            name="tableFade"
            mode="out-in"
          >
            <div
              v-if="loading.report || getPagination.totalItems === 0"
              class="grid grid-cols-12 grid-rows-6 gap-0 border relative border-neutral-50 mr-3 mt-3 rounded-lg"
            >
              <!-- Loading text-->
              <div
                v-if="loading.report || (getPagination.totalItems === 0 && loading.adGroups)"
                class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center"
              >
                <div>
                  <BaseLoadingSpinnerCircle
                    :width="24"
                    :height="24"
                    class="text-text-muted"
                  />
                </div>
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  Loading Data...
                </BaseText>
                <BaseText
                  class="text-text-normal w-64"
                  size="sm"
                >
                  One moment while we load the data. This might take up to a minute.
                </BaseText>
              </div>
              <div
                v-else-if="!loading.adGroups && getPagination.totalItems === 0"
                class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center"
              >
                <div>
                  <WarningIcon class="text-icon-normal" />
                </div>
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  No data found
                </BaseText>
                <BaseText
                  class="text-text-normal w-64"
                  size="sm"
                >
                  Try changing your filters
                </BaseText>
              </div>
              <!-- Grid background -->
              <div
                v-for="index in 36"
                :key="index"
                :class="{
                  'border-b': index <= 30,
                  'border-r': index % 30 !== 0,
                }"
                class="border-neutral-50 h-20 col-span-2"
              />
            </div>

            <LensMetricTable
              v-else-if="getPagination.totalItems > 0"
              class="mt-4"
              :grouped-by="report?.group_by"
              :table-pagination="getPagination"

              :loading="loading.adGroups"

              :table-columns="report?.table_columns"

              :graph-info="graphInfo"

              :selected-columns="selectedColumns[graphType]"
              :selected-rows.sync="selectedRows"
              :max-row-count="totalAllowedRows"

              :table-config="report?.table_config"
              :current-sort="report?.sorted_column"
              :data="tableResults"

              @update:selectedColumns="updateSelectedColumns"
              @update:selectedRows="updateSelectedRows"

              @paginationChanged="handlePaginationChange"
              @reportChanged="handleReportChange"

              @showGroupBreakdown="handleShowGroupBreakdown"
            />
            <div
              v-else
              class="w-full h-full flex flex-col items-center justify-center gap-1 text-center mt-52 my-auto"
            >
              <div>
                <WarningIcon class="text-icon-normal" />
              </div>
              <BaseText
                class="text-text-muted"
                type="label"
                size="sm"
              >
                No data found
              </BaseText>
              <div class="mt-3 space-y-2">
                <BaseText
                  class="text-neutral-500 w-64"
                  size="sm"
                >
                  Was this an error?
                </BaseText>
                <button
                  class="px-3 py-1.5 base-btn"
                  @click="openIntercom"
                >
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Contact Support
                  </BaseText>
                </button>
              </div>
            </div>
          </Transition>
        </div>

        <LensSettingsDrawer
          v-if="getColumnDrawerView"
          :table-columns="report?.table_columns"
          @reportChanged="handleReportChange"
          @close="closeColumnDrawer"
        />
      </div>
      <!-- Modals -->
      <EditPresetModal
        v-if="modal.showCreatePreset"
        key="new-preset"
        is-new
        @close="modal.showCreatePreset = false"
        @save="handleNewPreset"
      />
      <DuplicateReportModal
        v-if="modal.showDuplicateReport"
        :report="report"
        @close="modal.showDuplicateReport = false"
      />
      <ShareReportModal
        v-if="modal.showShareReport && !loading.report"
        :report="report"
        :current-graph="graphType"
        :current-date-range="selectedDateRange"
        :summary-stats="tableResults.summary_stats"
        :report-id="report?.id ?? $route.params?.reportId"
        :lens-id="$route.params?.lensId"
        :selected-rows="selectedRows"
        @close="modal.showShareReport = false"
      />
      <!-- Changes prompt -->
      <ReportEditSavePrompt
        :loading-info="editLoading"
        :original-report="originalReport"
        :changed-report="report"
        @save="handleReportUpdate"
        @cancel="handleReportCancel"
      />
      <!-- Drawers -->
      <GroupBreakdownDrawer
        v-if="showGroupBreakdown"
        :loading="loading.groupBreakdown"
        :group-data="groupBreakdownData"
        :current-lens="lens"
        :current-report="report"
        :selected-date-range="selectedDateRange"
        :report-summary-stats="tableResults.summary_stats"
        :graph-consts="{ maxRows: MAX_GRAPH_ROWS, maxCols: MAX_GRAPH_COLS }"
        :init-selected-columns="selectedColumns"
        :init-graph-type="graphType === 'line' ? 'grid' : graphType"
        @close="handleCloseBreakdown"
      />
    </template>
  </MainLayout>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import _ from 'lodash'
import axios from 'axios'
import LensAPI from '@/api/lens'
import {
  createFilterQuery,
  parseFiltersFromQuery,
  parseDateRangeFromQuery
} from '@/utils/lens/filters/filterQueryUtils'

// Components
import MainLayout from '../../layouts/MainLayout.vue'
import LensFilterToolbar from '../../components/lens/filters/LensFilterToolbar.vue'
import LensMetricTable from '../../components/lens/table/LensMetricTable.vue'
import LensSettingsDrawer from '../../components/settings/LensSettingsDrawer.vue'
import LensTableOptions from '../../components/lens/table/LensTableOptions.vue'
import ReportEditSavePrompt from '../../components/lens/report/ReportEditSavePrompt.vue'
import LensReportGraph from '../../components/lens/graphs/LensReportGraph.vue'
import LensReportHeader from '../../components/lens/report/LensReportHeader.vue'
import GroupBreakdownDrawer from '../../components/lens/report/GroupBreakdownDrawer.vue'
import EditPresetModal from '../../components/lens/settings/EditPresetModal.vue'
import DuplicateReportModal from '../../components/lens/report/DuplicateReportModal.vue'
import ShareReportModal from '../../components/lens/report/share/ShareReportModal.vue'
import ComparaitiveGroupBuilder from '../../components/lens/report/ComparaitiveGroupBuilder.vue'
// Icons
import WarningIcon from '../../components/globals/Icons/WarningIcon.vue'

export default {
  name: 'LensReportView',
  components: {
    MainLayout,
    LensFilterToolbar,
    LensMetricTable,
    LensSettingsDrawer,
    LensReportGraph,
    LensTableOptions,
    EditPresetModal,
    ReportEditSavePrompt,
    LensReportHeader,
    GroupBreakdownDrawer,
    DuplicateReportModal,
    ComparaitiveGroupBuilder,
    ShareReportModal,
    // Icons
    WarningIcon
  },
  mixins: [clickaway],
  data () {
    const groupOptions = [
      { name: 'Ad Name', value: 'ad_name' },
      { name: 'Ad Copy', value: 'ad_copy' },
      { name: 'Landing Page', value: 'destination_url' },
      { name: 'Creative', value: 'creative' }
    ]

    const MAX_GRAPH_ROWS = {
      bar: 8,
      line: 8,
      grid: 12
    }
    const MAX_GRAPH_COLS = {
      bar: 4,
      line: 2,
      grid: 12
    }
    return {
      MAX_GRAPH_ROWS,
      MAX_GRAPH_COLS,

      groupOptions,

      appliedGroup: groupOptions[0],

      graphType: 'bar',
      selectedColumns: {
        bar: [],
        line: [],
        grid: []
      },

      selectedRows: [],

      groupBreakdownData: null,
      currentConditions: null,

      showDropdown: {
        tableSettings: false,
        group: false
      },
      showColumnDrawer: false,
      showGroupBuilder: false,

      modal: {
        showCreatePreset: false,
        showDuplicateReport: false,
        showShareReport: false
      },

      // Table
      tableResults: {},

      // Lens
      lens: null,
      // Report
      wasCustom: false,
      originalReport: {},
      report: {},
      reportNotFound: false,
      reportType: null,

      // Query Filters
      comparaitiveQuery: [],
      appliedFilters: [],
      selectedDateRange: {
        start: null,
        end: null,
        isRelativeToToday: false
      },

      // Update type with error or success to enable ReportEditSavePrompt with ui
      editLoading: {
        type: null,
        value: false
      },

      loading: {
        report: true,
        adGroups: false,
        groupBreakdown: false
      },

      hasMounted: false
    }
  },
  watch: {
    graphType (type) {
      if (this.tableResults?.ad_groups?.length > 0) {
        const rowCount = this.report?.selected_graph_rows[type]
        this.selectedRows = this.tableResults.ad_groups.slice(0, rowCount)
        this.updateRowColor({keys: this.selectedRows, isAdding: true, graph: type} )
      }
      this.report.graph_type = type
    },
    appliedFilters (filters) {
      this.handleFiltersChange(filters, this.selectedDateRange)
    },
    selectedDateRange (dateRange) {
      this.handleFiltersChange(this.appliedFilters, dateRange)
    },
    comparaitiveQuery (query) {
      if (!this.hasMounted) return
      const noActiveConditions = query.every((q) => q.query.length === 0)
      if (query.length === 0 || noActiveConditions) {
        // If no query reset
        this.tableResults.ad_groups = []
        this.updatePagination({
          currentPage: 1,
          limit: 10,
          totalItems: 0,
          totalPages: 0
        })
        LensAPI.AdGroups.abortGroupRequest()
        this.loading.adGroups = false
      }
      const change = { type: 'custom_groups_filters', value: query, preventFetch: query.length === 0 }
      this.handleReportChange(change)
    }
  },
  beforeDestroy () {
    this.updatePagination({
      currentPage: 1,
      limit: 10,
      totalItems: 0,
      totalPages: 0
    })
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset', 'getPresets', 'getColumnDrawerView', 'getPagination']),
    showGroupBreakdown () {
      return this.loading.groupBreakdown || this.groupBreakdownData !== null
    },
    totalComparaitiveGroups () {
      return this.comparaitiveQuery?.length ?? 0
    },
    comparaitiveInfo () {
      if (!this.tableResults || !this.tableResults?.ad_groups) return

      return this.tableResults.ad_groups.reduce((acc, adGroup) => {
        const { active_ad_count: activeAds, paused_ad_count: pausedAds, inactive_ad_count: inactiveAds } = adGroup || {}
        const nonZeroAds = adGroup.ads.filter((ad) => ad.spend > 0)
        const totalAds = activeAds + pausedAds + inactiveAds
        acc[adGroup.group_by] = { adCount: totalAds, spentCount: nonZeroAds.length }
        return acc
      }, {})
    },
    graphInfo () {
      return {
        selectedGraph: this.graphType,
        data: {
          bar: {
            selected: this.report?.selected_columns?.bar?.length || 0,
            total: this.MAX_GRAPH_COLS.bar
          },
          line: {
            selected: this.report?.selected_columns?.line?.length || 0,
            total: this.MAX_GRAPH_COLS.line
          },
          grid: {
            selected: this.report?.selected_columns?.grid?.length || 0,
            total: this.MAX_GRAPH_COLS.grid
          }
        }
      }
    },
    graphDataItems () {
      const totalItems = this.getPagination.totalItems
      return totalItems > 0 ? this.selectedRows : []
    },
    graphDataSummary () {
      return {
        ...this.tableResults.summary_stats,
        startDate: this.selectedDateRange.start,
        endDate: this.selectedDateRange.end,
        groupedBy: this.report?.group_by,
        sort: this.report?.sorted_column,
        filters: this.report?.filters,
        currentWindow: { click: this.report.click_attribution_window, view: this.report.view_attribution_window }
      }
    },
    reportRowCount () {
      return this.report?.selected_graph_rows[this.graphType]
    },
    totalAllowedRows () {
      return this.MAX_GRAPH_ROWS?.[this.graphType] ?? 8
    },
    isSelectedColsMaxed () {
      const currLength = this.selectedColumns[this.graphType].length
      return currLength >= this.graphInfo.data[this.graphType].total
    }
  },
  async mounted () {
    // Set initial top bar styles
    this.$nextTick(() => {
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.position = 'sticky'
        this.$refs.filterBar.style.top = '16px'
        this.$refs.filterBar.style.zIndex = '40000'
      }
    })

    await this.fetchLens()
    await this.fetchPresets()
    const reportId = this.$route.params.reportId
    await this.fetchReport(reportId)

    if (this.reportNotFound) {
      this.$showAlert({ type: 'error', message: 'Report not found' })
      return
    }
    this.loading.report = false
    const preventInitFetch = this.reportType === 'comparaitive' ? this.comparaitiveQuery?.length === 0 : false
    if (!preventInitFetch) {
      await this.fetchAdGroups()
    } else {
      this.showGroupBuilder = true
    }
    this.hasMounted = true
  },
  methods: {
    ...mapActions('LensModule', ['updateRowColor', 'fetchPresets', 'updateColorFormat', 'fetchAdGroups', 'setPresetById', 'updateIsCustomPreset', 'updatePagination']),
    ...mapMutations('LensModule', ['SET_COLUMN_DRAWER_VIEW']),
    async fetchAdGroups (query) {
      this.loading.adGroups = true
      const report = this.report
      const page = query?.page

      const group = query?.group_by ?? this.report?.group_by
      const sort = query?.sort ?? this.report?.sorted_column
      const currentFilter = query?.filters ?? report.filters
      const currentPage = page?.currentPage ?? (this.getPagination?.currentPage || 1)
      const limit = page?.limit ?? (this.getPagination?.limit || 10)
      const currentWindow = query?.attribution_window ?? { click: this.report.click_attribution_window, view: this.report.view_attribution_window }
      const tableColumns = query?.tableColumns ?? this.report?.table_columns
      const customGroupQuery = this.comparaitiveQuery ? this.comparaitiveQuery.filter((group) => group.query.length > 0) : []
      const payload = {
        group,
        filters: currentFilter,
        page: currentPage,
        limit,
        sort,
        currentWindow,
        ...(this.reportType === 'comparaitive' && { comparaitiveQuery: customGroupQuery }),
        ...(this.reportType === 'comparaitive' && { selectedColumns: tableColumns })
      }
      try {
        const res = await LensAPI.AdGroups.getLensGroups(this.$route.params.lensId, payload, this.reportType)
        this.tableResults = res?.data ?? {}
        this.updatePagination({ ...this.getPagination, totalPages: res.pages, totalItems: res.total })

        if (res?.total === 0 || res.data.length === 0) {
          return
        }

        // Prevents setting rows on pagination/query changes
        if (!this.hasMounted || !(query?.page || query?.limit) || query?.sort) {
          const maxRowsForGraph = this.MAX_GRAPH_ROWS[this.graphType]
          const rowCount = this.reportType === 'comparaitive' ? Math.min(res.data.ad_groups.length, maxRowsForGraph) : this.report?.selected_graph_rows[this.graphType]
          const adGroupRows = res.data.ad_groups.slice(0,rowCount)
          this.updateSelectedRows(adGroupRows)
          this.updateRowColor({keys: adGroupRows, isAdding: true, graph: this.graphType})
        }
        this.loading.adGroups = false
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('New query detected: Prev request canceled')
        } else {
          console.error(e)
          this.$showAlert({
            type: 'error',
            message: 'An unexpected error occurred'
          })
          this.loading.adGroups = false
        }
      }
    },
    closeColumnDrawer () {
      this.SET_COLUMN_DRAWER_VIEW(false)
    },
    async fetchReport (id) {
      const res = await LensAPI.LensReport.getByID(id)
      const data = res.data
      if (_.isEmpty(data)) {
        this.reportNotFound = true
        return
      }
      const dataTableConfig = data.table_config

      // presetId should be null if column hashes dont match on fetch. Indicating a custom report
      const isColHashEqual = data.preset?.columns_hash === data.columns_hash
      const presetId = isColHashEqual ? data.preset?.id : null
      console.log('%c FETCHING REPORT', 'font-size:18px')
      if (presetId) {
        this.updateIsCustomPreset(false)
        this.setPresetById({ id: presetId, shouldSelect: true })
      } else this.updateIsCustomPreset(true)
      this.wasCustom = !isColHashEqual
      // Object should only contain acceptable fields
      const tableConfig = {
        ...dataTableConfig,
        colorFormat: dataTableConfig?.colorFormat || '',
        showStatus: dataTableConfig?.showStatus || false,
        showTags: dataTableConfig?.showTags || false
      }
      this.updateColorFormat(tableConfig.colorFormat)
      this.reportType = data?.type
      const reportData = {
        name: data?.name,
        description: data?.description,
        group_by: data?.group_by,
        filters: data?.filters,
        graph_type: data?.graph_type,
        click_attribution_window: data?.click_attribution_window,
        view_attribution_window: data?.view_attribution_window,
        selected_columns: data?.selected_columns,
        selected_graph_rows: data?.selected_graph_rows,
        table_columns: data?.table_columns,
        preset_id: presetId,
        custom_groups_filters: data?.custom_groups_filters,
        columns_hash: data?.columns_hash,
        sorted_column: data?.sorted_column || { by: 'spend', order: 'desc' },
        table_config: tableConfig
      }

      this.originalReport = _.cloneDeep(reportData)
      this.report = reportData
      this.appliedFilters = parseFiltersFromQuery(data?.filters)
      this.selectedDateRange = parseDateRangeFromQuery(data?.filters)
      this.comparaitiveQuery = data?.custom_groups_filters || []
      this.graphType = data?.graph_type ?? this.graphType
      // Initalize selected rows & columns (rows must be initalized based on query data)
      this.selectedColumns = { ...data?.selected_columns }
      // this.updateSelectedColumns(this.report.selected_columns[this.graphType])
    },
    async fetchLens () {
      const res = await LensAPI.Lens.get(this.$route.params.lensId)
      this.lens = res.data
    },
    async handleNewPreset (preset) {
      try {
        const newPreset = {
          ...preset,
          table_columns: []
        }
        await LensAPI.LensPreset.create(newPreset)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
      await this.fetchPresets()
    },
    handleFiltersChange (filters, dateRange) {
      if (!this.hasMounted) return
      const filterQuery = createFilterQuery(filters, dateRange)
      this.report.filters = filterQuery
      this.fetchAdGroups({ filters: filterQuery })
    },
    // Report actions
    handleReportChange (change) {
      console.log(`%c Updating ${change.type}`, 'color: pink; font-size: 18px;')
      const { type, value, preventFetch } = change
      this.report = {
        ...this.report,
        [type]: value
      }

      // Syncs selected columns with table column - required to prevent selectedColumns stale state when table_columns is updated
      if (type === 'table_columns' && this.selectedColumns.length !== value.length) {
        const selectedColsSet = new Set(this.selectedColumns[this.graphType])
        const newSelectedCols = value.reduce((acc, col) => {
          if (selectedColsSet.has(col.key)) acc.push(col.key)
          return acc
        }, [])
        this.updateSelectedColumns(newSelectedCols, true)
      }
      if (!preventFetch) {
        this.fetchAdGroups({
          ...(type === 'sorted_column' ? this.report.sorted_column : null)
        })
      }
    },
    // Updates that dont require a refetch
    handleLocalReportChange (toUpdate) {
      this.report = {
        ...this.report,
        ...toUpdate
      }
      this.originalReport = {
        ...this.originalReport,
        ...toUpdate
      }
    },
    handlePaginationChange (change) {
      console.log('%c Updating Pagination', 'color: pink; font-size: 18px;')
      const { type, value, preventFetch } = change

      // If no type specified, spread into object parents
      const newPagination = type ? {
        ...this.getPagination,
        [type]: value
      } : {
        ...this.getPagination,
        ...value
      }
      this.updatePagination(newPagination)
      if (!preventFetch) {
        this.fetchAdGroups({ page: value?.currentPage, limit: value?.limit })
      }
    },
    async handleReportUpdate (toUpdate) {
      console.log('%c SAVING REPORT', 'color: orange; font-size: 18px')
      // Init async loading state
      this.editLoading = {
        type: null,
        value: true
      }
      try {
        const reportId = this.report?.id || this.$route.params?.reportId
        console.log('SAVING', toUpdate)
        await LensAPI.LensReport.update(reportId, toUpdate)

        // Show success message & wait before dismiss & refetch
        this.editLoading = {
          type: 'success',
          value: false
        }

        // TODO @Sam: handle spam
        setTimeout(async () => {
          this.editLoading = {
            type: null,
            value: false
          }
        }, 1500)
        this.wasCustom = this.getIsCustomPreset
        this.originalReport = _.cloneDeep(this.report)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
        this.editLoading = {
          type: null,
          value: false
        }
      }
    },
    updateEditLoad (type, value) {
      this.editLoading = {
        type,
        value
      }
    },
    async handleReportCancel (shouldFetch = false) {
      // Reset changed report to original
      this.report = _.cloneDeep(this.originalReport)
      this.updateColorFormat(this.originalReport?.table_config?.colorFormat || '')

      // Reset cols to original report, and rows to first X (rowCount) rows
      const selectedColumns = this.originalReport.selected_columns
      this.selectedColumns = { ...selectedColumns }

      const originalFilters = this.originalReport?.filters
      const originalCustomFilters = this.originalReport?.custom_groups_filters

      this.graphType = this.originalReport?.graph_type
      const presetId = this.originalReport.preset_id
      if (presetId) this.setPresetById({ id: presetId, shouldSelect: true })
      this.updateIsCustomPreset(this.wasCustom)
      if (shouldFetch) {
        this.appliedFilters = originalFilters ? parseFiltersFromQuery(originalFilters) : []
        this.selectedDateRange = originalFilters ? parseDateRangeFromQuery(originalFilters) : {
          start: null,
          end: null
        }
        this.comparaitiveQuery = originalCustomFilters || []
        await this.fetchAdGroups()
        const rowCount = this.originalReport?.selected_graph_rows[this.graphType]
        this.selectedRows = this.tableResults.ad_groups.slice(0, rowCount)
      }
    },
    updateSelectedRows (rows) {
      this.selectedRows = rows

      const rowLength = rows.length
      // Consistent row selection until better ux is determined
      this.report.selected_graph_rows[this.graphType] = rowLength
    },
    updateSelectedColumns (columns, applyToAll) {
      // We need to check if we're adding new columns to the report
      // TODO: Might want to block this func all together if table_columns is undefined

      const existingTableColumns = new Set(this.report?.table_columns?.map(col => col.key) || [])
      const newTableColumns = [...columns].filter(column => !existingTableColumns.has(column))
      if (newTableColumns.length) {
        // If there are new columns, we need to update the table_columns state to include them
        const change = {
          type: 'table_columns',
          value: [
            ...this.report?.table_columns || [],
            ...newTableColumns.map(col => ({ key: col, is_pinned: false }))
          ],
          preventFetch: true
        }
        this.handleReportChange(change)
        this.updateIsCustomPreset(true) // Flag the preset as custom
      }

      // Update selected columns
      if (!applyToAll) {
        this.selectedColumns[this.graphType] = columns
        this.report.selected_columns[this.graphType] = [...columns]
      } else {
        this.selectedColumns = {
          bar: columns,
          line: columns,
          grid: columns
        }
        this.report.selected_columns = {
          bar: columns,
          line: columns,
          grid: columns
        }
      }
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        if (position.scrollTop > 0) this.$refs.filterBar.classList.add('toolbar-container')
        this.$refs.filterBar.style.setProperty('border-radius', `${shadowSection(14)}px`, 'important')
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.classList.remove('toolbar-container')
        }
      }
    },
    handleShowGroupBreakdown (row) {
      if (this.reportType === 'comparaitive') {
        this.setBreakdownData(row.group_by)
      } else {
        this.groupBreakdownData = row
      }
    },
    async setBreakdownData (condId) {
      this.loading.groupBreakdown = true
      try {
        const conditionData = this.comparaitiveQuery.filter((group) => group.group_by === condId)
        const payload = {
          currentWindow: {
            click: this.report.click_attribution_window,
            view: this.report.view_attribution_window
          },
          comparaitiveQuery: conditionData,
          filters: this.report?.filters,
          selectedColumns: this.report.table_columns
        }
        const res = await LensAPI.AdGroups.getLensGroupAds(this.lens.id, payload)
        const group = res.data.ad_groups[0]
        this.groupBreakdownData = {
          ...group,
          previous_period: group.previous_period || {},
          summary_stats: res.data.summary_stats || {}
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('New query detected: Prev request canceled')
        } else {
          console.error(e)
          this.$showAlert({
            type: 'error',
            message: 'An unexpected error occurred'
          })
        }
      } finally {
        this.loading.groupBreakdown = false
      }
    },
    handleCloseBreakdown () {
      LensAPI.AdGroups.abortBreakdownRequest()
      this.groupBreakdownData = null
    },
    openIntercom () {
      window.Intercom('show')
    }
  }
}
</script>

<style scoped>
  .dropdown-scrollable {
    transition: colors 250ms ease-in-out;
    top: 100%;
    z-index: 999;
  }
  .dropdown-scrollable::-webkit-scrollbar {
    width: 3px;
  }
  .dropdown-scrollable::-webkit-scrollbar-thumb {
    background-color: #DFE1E7;
    border-radius: 18px;
  }

  .grid-background{
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #ECEFF3 1px, transparent 1px),
    linear-gradient(to bottom, #ECEFF3 1px, transparent 1px);
  background-size: 124px 82px;
  }

  .tableFade-enter-active,
  .tableFade-leave-active {
    transition: all 0.25s ease-out;
  }

  .tableFade-enter-from {
    opacity: 0;
    transform: translateY(1rem);
  }

  .tableFade-leave-to {
    opacity: 0;
    transform: translateY(-1rem);
  }
  .base-btn {
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13),
      0px 0px 0px 1px rgba(0, 56, 108, 0.08);
    transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
    border-radius: 6px;
    padding: 6px 8px 6px 8px;
  }

  .base-btn:hover {
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17),
      0px 0px 0px 1px rgba(0, 56, 108, 0.12);
  }

  .toolbar-container{
    border: none !important;
    box-shadow:
      0px 28px 28px -14px rgba(9, 25, 72, 0.06),
      0px 12px 12px -6px rgba(9, 25, 72, 0.06),
      0px 3px 3px -1.5px rgba(9, 25, 72, 0.06),
      0px 1px 1px -0.5px rgba(9, 25, 72, 0.06),
      0px 0px 0.5px 0.75px rgba(9, 25, 72, 0.06) !important
  }

  /* Section transitions */
  .expand-enter-active,
  .expand-leave-active {
    transition-property: opacity, height;
    transition-duration: 250ms;
  }

  .expand-enter,
  .expand-leave-to {
    opacity: 0;
  }
</style>
