<template>
  <!-- Manage Board Modal -->
  <BaseModal
    card-width="400px"
    no-padding
    no-toolbar-padding
    no-exit
    @close="$emit('close')"
  >
    <template #toolbar>
      <div
        class="flex items-center gap-2"
      >
        <DeleteIcon />
        <BaseText
          class="text-text-muted"
          size="sm"
          type="label"
        >
          {{ confirmHeader }}
        </BaseText>
      </div>
      <button
        class="absolute top-3 right-3 p-1.5"
        @click="$emit('close')"
      >
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>

    <template #default>
      <div
        key="successReport"
      >
        <div
          class="px-5 pb-5 pt-2 border-b"
        >
          <BaseText
            size="sm"
            class="text-neutral-500"
          >
            {{ deleteDisclaimer }}
          </basetext>
        </div>
      </div>
      <div class="p-4">
        <div class="flex items-center justify-between">
          <BaseButton
            class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
            @click="$emit('close')"
          >
            Cancel
          </BaseButton>
          <BaseButton
            :class="confirmBtnClass"
            @click="$emit('confirm')"
          >
            <BaseLoadingSpinnerCircle
              v-if="loading"
            />

            <BaseText
              v-else
              type="label"
              size="sm"
            >
              {{ confirmText }}
            </BaseText>
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import DeleteIcon from './Icons/DeleteIcon.vue'
import TagRemoveIcon from './Icons/TagRemoveIcon.vue'
export default {
  name: 'BaseConfirmDeleteModal',
  components: {
    TagRemoveIcon,
    DeleteIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    confirmBtnClass: {
      type: String,
      default: () => 'border border-red-300 text-red-500 hover:bg-red-50'
    },
    confirmText: {
      type: String,
      default: () => 'Delete'
    },
    deleteDisclaimer: {
      type: String,
      default: () => 'This action can\'t be undone.'
    },
    confirmHeader: {
      type: String,
      default: () => 'Are you sure?'
    }
  }
}
</script>
