<template>
  <div
    v-on-clickaway="() => { isExpanded = false }"
    class="preset-select hover:bg-background-hover p-1.5 rounded-md cursor-pointer transition-colors relative"
    :class="isExpanded ? 'bg-background-hover' : ''"
    @click="isExpanded = !isExpanded"
  >
    <div
      class="flex items-center gap-2 w-full"
    >
      <!-- Prefix -->
      <BaseText
        size="sm"
        class="text-text-normal flex-shrink-0"
      >
        Selected Preset
      </BaseText>
      <!-- Custom identifier -->
      <div
        v-if="!hasSelectedPreset && getIsCustomPreset"
        class="flex-shrink"
      >
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted truncate"
        >
          Custom
        </BaseText>
      </div>

      <!-- Preset identifier -->
      <BaseText
        v-else
        class="text-text-muted truncate flex-shrink"
        type="label"
        size="sm"
      >
        {{ selectedPreset?.name }}
      </BaseText>
      <BaseText
        v-if="selectedPreset?.is_default && !(!hasSelectedPreset && getIsCustomPreset)"
        class="rounded-md bg-white preset-tag shadow-sm px-1.5 py-0.5"
        type="label"
        size="xs"
      >
        Default
      </BaseText>
      <div
        class="transform transition-transform duration-200 flex-shrink-0"
        :style="isExpanded ? 'transform: scale(1, -1)' : ''"
      >
        <ChevronIcon class="text-icon-muted" />
      </div>
    </div>
    <!-- Expanded preset dropdown list -->
    <transition name="fade-up">
      <div
        v-if="isExpanded"
        class="dropdown-expand overflow-hidden bg-white rounded-lg left-0 right-0 mx-auto z-50 "
      >
        <div class="flex flex-col pt-1 px-1 max-h-56 overflow-y-auto">
          <div
            v-for="presetItem in sortedPresets"
            :key="`preset-${presetItem.id}`"
            class="flex items-center justify-between py-1.5 px-2 group hover:bg-background-normal rounded-md"
            @click="handleNewSelect(presetItem.id)"
          >
            <div class="flex items-center gap-2">
              <BaseText
                size="sm"
                class="group-hover:text-text-muted text-neutral-500 transition-colors"
              >
                {{ presetItem.name }}
              </BaseText>
              <div
                v-if="presetItem.is_default"
                class="rounded-md bg-white preset-tag px-1.5 py-0.5"
              >
                <BaseText
                  type="label"
                  size="xs"
                >
                  Default
                </BaseText>
              </div>
            </div>
            <div
              v-if="(presetItem.id === selectedPreset.id) && !getIsCustomPreset"
              class="text-text-muted"
            >
              <CheckmarkIcon />
            </div>
          </div>
        </div>
        <div v-if="!disableCta">
          <!-- Divider -->
          <div class="h-0 mt-1 cta-divider" />
          <!-- Create new cta -->
          <button
            class="flex items-center justify-center gap-1.5 w-full py-1.5 px-2 hover:bg-background-normal"
            @click="showCreateModal"
          >
            <PlusIcon class="text-icon-normal" />
            <BaseText
              class="text-text-muted"
              type="label"
              size="sm"
            >
              Create New Preset
            </BaseText>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import CheckmarkIcon from '../globals/Icons/CheckmarkIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import PlusIcon from '../globals/Icons/PlusIcon.vue'
export default {
  name: 'SelectedPresetDropdown',
  components: {
    CheckmarkIcon,
    ChevronIcon,
    PlusIcon
  },
  mixins: [clickaway],
  props: {
    selectedPreset: {
      type: Object,
      default: () => {}
    },
    disableCta: {
      type: Boolean,
      default: () => false
    },
    hasSelectedPreset: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getPresets', 'getIsCustomPreset']),
    // Sort: Default -> Selected -> Rest
    sortedPresets () {
      // TODO @Sam: fix preset sorting
      // const selectedPreset = this.selectedPreset
      // const defaultPreset = this.getPresets.find(preset => preset.is_default && preset.id !== this.selectedPreset.id)
      // const otherPresets = this.getPresets.filter(preset => preset.id !== this.selectedPreset.id && (!defaultPreset || preset.id !== defaultPreset.id))

      // let sortedPresets = []
      // if (defaultPreset) sortedPresets.push(defaultPreset)
      // if (selectedPreset) sortedPresets.push(selectedPreset)
      // sortedPresets = sortedPresets.concat(otherPresets)
      return this.getPresets
    }
  },
  methods: {
    handleNewSelect (id) {
      this.$emit('handleSelect', id)
    },
    showCreateModal () {
      this.$emit('handleShowModal')
    }
  }
}
</script>

<style scoped>
.dropdown-expand{
  position: absolute;
  top: 100%;
  margin-top: 4px;

  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  box-shadow: 0px 1px 2px 0px rgba(9, 25, 72, 0.13);
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 150ms;
}

.fade-up-enter-from, .fade-up-enter, .fade-up-leave-to{
  opacity: 0;
  transform: translateY(-0.5rem);
}

.cta-divider{
  border-top-width: 1px;
  border-color: rgba(18, 55, 105, 0.08);
}

.preset-select{
  min-width: 280px;
  /* max-width: 350px; */
}
</style>
