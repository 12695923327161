<template>
  <div>
    <div
      v-if="showInSearch"
      class="flex items-center gap-1 mb-2.5"
    >
      <component :is="sectionIcon" />
      <!-- Render header if we have metric results -->
      <BaseText
        :id="sectionId"
        :ref="sectionId"
        class="capitalize text-text-muted metric-section"
        type="heading"
        size="sm"
      >
        {{ sectionId }}
      </BaseText>
    </div>
    <div
      v-for="metric in searchList.metrics"
      :id="`metric-${metric.key}`"
      :key="metric.key"
      class="flex items-center gap-2 relative p-1.5 hover:bg-background-normal cursor-pointer rounded-lg"
      @click="handleMetricAdd(metric.key, sectionId)"
      @mouseenter="addHover(metric)"
      @mouseleave="addHover(null)"
    >
      <input
        type="checkbox"
        :checked="presetList.includes(metric.key)"
        class="h-4 w-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent transition-colors"
      >
      <BaseText
        type="label"
        size="sm"
        class="text-neutral-600"
      >
        {{ metric.name }}
      </BaseText>
    </div>
    <!-- Sub section list -->
    <div
      v-for="(subSection, index) in searchList.subSections"
      :key="index"
    >
      <div>
        <BaseText
          :id="subSection.domId"
          :ref="subSection.domId"
          type="heading"
          size="overline"
          class="text-text-subdued mt-4 mb-1 uppercase metric-section"
        >
          {{ subSection.sectionId }}
        </BaseText>
        <div class="flex flex-col gap-1">
          <div
            v-for="metric in subSection?.metrics"
            :id="`metric-${metric.key}`"
            :key="metric.key"
            class="flex items-center gap-2 relative p-1.5 hover:bg-background-normal cursor-pointer rounded-lg"
            @click="handleMetricAdd(metric.key, sectionId)"
            @mouseenter="addHover(metric)"
            @mouseleave="addHover(null)"
          >
            <input
              type="checkbox"
              :checked="presetList.includes(metric.key)"
              class="h-4 w-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent transition-colors"
            >
            <BaseText
              class="capitalize text-neutral-600"
              type="label"
              size="sm"
            >
              {{ metric.name }}
            </BaseText>
          </div>
        </div>
      </div>
    </div>
    <!-- Table section list -->
    <div
      v-for="tableSection in searchList.tableSections"
      :key="tableSection.sectionId"
    >
      <div>
        <div class="grid grid-cols-12 place-content-center mt-4 mb-1 bg-background-disabled rounded-lg p-2.5 h-9">
          <BaseText
            :id="tableSection.domId"
            :ref="tableSection.domId"
            type="heading"
            size="overline"
            class="text-text-subdued  uppercase metric-section col-span-8"
          >
            {{ tableSection.sectionId.replace(/_/g, ' ') }}
          </BaseText>
          <div class="col-span-4 grid grid-cols-12 place-items-center">
            <BaseText
              v-for="tableHeader in tableSection.colHeaders"
              :key="tableHeader"
              class="col-span-4 text-text-subdued uppercase"
              type="heading"
              size="overline"
            >
              {{ tableHeader }}
            </BaseText>
          </div>
        </div>
        <div
          v-for="row in tableSection.metrics"
          :key="row.rowName"
          class="grid grid-cols-12 place-content-center relative p-1.5 rounded-lg"
        >
          <BaseText
            class="capitalize text-neutral-600 col-span-8"
            type="label"
            size="sm"
          >
            {{ row.rowName }}
          </BaseText>
          <div class="col-span-4 grid grid-cols-12 place-items-center">
            <input
              v-for="(tableMetric) in row.metrics"
              :id="`metric-${tableMetric.key}`"
              :key="tableMetric.key"
              type="checkbox"
              class="h-4 w-4 col-span-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent transition-colors"
              @click="handleMetricAdd(tableMetric.key, sectionId)"
              @mouseenter="addHover(tableMetric)"
              @mouseleave="addHover(null)"
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showInSearch"
      class="w-full border-t border-border-normal my-8"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'MetricSectionList',
  props: {
    presetList: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    },
    sectionId: {
      type: String,
      default: () => ''
    },
    sectionIcon: {
      type: Object,
      default: () => null
    },
    fuzzyResults: {
      type: Set,
      default: () => new Set()
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricSearch']),
    showInSearch () {
      return (this.getMetricSearch.length === 0 || (this.searchList.metrics.length > 0 || this.searchList.subSections.length > 0 || this.searchList.tableSections.length > 0))
    },
    searchList () {
      // Handle user search
      if (this.getMetricSearch.length > 0) {
        // Base metrics
        const searchMetrics = this.filterSearchResults(this.data.metrics)

        // Sub metrics
        const searchSubs = this.data.subSections.reduce((acc, section) => {
          const filteredMetrics = this.filterSearchResults(section.metrics)
          if (filteredMetrics.length > 0) {
            acc.push({ ...section, metrics: filteredMetrics })
          }
          return acc
        }, [])

        // Table metrics
        const searchTables = this.data.tableSections?.length > 0 ? this.data.tableSections.reduce((acc, section) => {
          const filteredMetrics = section.metrics.filter((row) => row.metrics.some((metric) => this.fuzzyResults.has(metric.key)))
          if (filteredMetrics.length > 0) {
            acc.push({ ...section, metrics: filteredMetrics })
          }
          return acc
        }, []) : []

        return {
          metrics: searchMetrics,
          subSections: searchSubs,
          tableSections: searchTables
        }
      }
      // All metrics
      return {
        metrics: this.data.metrics,
        subSections: this.data.subSections,
        tableSections: this.data.tableSections
      }
    }
  },
  methods: {
    filterSearchResults (arr) {
      return arr.filter((val) => this.fuzzyResults.has(val.key))
    },
    addHover (metricKey) {
      this.$emit('handleHover', metricKey)
    },
    handleMetricAdd (metricKey, parentId) {
      this.$emit('add', metricKey, parentId)
    }
  }
}
</script>

<style scoped>
.checkbox-input {
  border: 1px;
  box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(9, 25, 72, 0.13);
}
</style>
