
<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="magic box">
      <g id="Icon">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6021 3.23351C16.8873 3.42786 16.961 3.81667 16.7667 4.10193L14.4515 7.50002H16.0418C16.387 7.50002 16.6668 7.77984 16.6668 8.12502V16.0417C16.6668 17.3073 15.6408 18.3334 14.3752 18.3334H5.62516C4.35951 18.3334 3.3335 17.3073 3.3335 16.0417V8.12502C3.3335 7.77984 3.61332 7.50002 3.9585 7.50002H12.9389L15.7337 3.39811C15.928 3.11285 16.3168 3.03915 16.6021 3.23351ZM4.5835 8.75002V16.0417C4.5835 16.617 5.04987 17.0834 5.62516 17.0834H14.3752C14.9505 17.0834 15.4168 16.617 15.4168 16.0417V8.75002H4.5835Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.50016 11.4584C7.50016 11.1132 7.77998 10.8334 8.12516 10.8334H11.8752C12.2203 10.8334 12.5002 11.1132 12.5002 11.4584C12.5002 11.8035 12.2203 12.0834 11.8752 12.0834H8.12516C7.77998 12.0834 7.50016 11.8035 7.50016 11.4584Z"
          fill="currentColor"
        />
        <path
          d="M9.87523 2.56214C9.95587 2.52182 10.0213 2.45644 10.0616 2.3758L10.4601 1.57872C10.6137 1.27161 11.0519 1.27161 11.2055 1.57872L11.604 2.3758C11.6443 2.45644 11.7097 2.52182 11.7903 2.56214L12.5874 2.96068C12.8945 3.11423 12.8945 3.55249 12.5874 3.70604L11.7903 4.10458C11.7097 4.1449 11.6443 4.21028 11.604 4.29092L11.2055 5.088C11.0519 5.39511 10.6137 5.39511 10.4601 5.088L10.0616 4.29092C10.0213 4.21028 9.95587 4.1449 9.87523 4.10458L9.07815 3.70604C8.77104 3.55249 8.77104 3.11423 9.07815 2.96068L9.87523 2.56214Z"
          fill="currentColor"
        />
        <path
          d="M5.43079 3.95103C5.51142 3.91071 5.57681 3.84533 5.61713 3.76469L5.87678 3.24538C6.03033 2.93828 6.46858 2.93828 6.62213 3.24538L6.88179 3.76469C6.92211 3.84533 6.98749 3.91071 7.06813 3.95103L7.58743 4.21068C7.89454 4.36423 7.89454 4.80249 7.58743 4.95604L7.06813 5.21569C6.98749 5.25601 6.92211 5.32139 6.88179 5.40203L6.62214 5.92134C6.46858 6.22844 6.03033 6.22844 5.87678 5.92134L5.61713 5.40203C5.57681 5.32139 5.51142 5.25601 5.43079 5.21569L4.91148 4.95604C4.60438 4.80249 4.60438 4.36423 4.91148 4.21068L5.43079 3.95103Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MagicBoxIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
