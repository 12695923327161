import * as ViewIcons from '../../components/globals/Icons/LensIcons'

const sectionIcons = {
  performance: ViewIcons.PerformanceIcon,
  engagement: ViewIcons.EngagementIcon,
  media: ViewIcons.MediaIcon,
  conversions: ViewIcons.ConversionIcon,
  customMetrics: ViewIcons.CustomMetricIcon
}

export default sectionIcons
