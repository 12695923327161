<template>
  <div
    class="p-1 flex items-center justify-between gap-x-4 w-full bg-background-normal rounded-lg"
  >
    <!-- Preset info -->
    <SelectedPresetDropdown
      :selected-preset="getLocalPreset"
      :has-selected-preset="hasSelectedPreset"
      @handleShowModal="showCreateModal = true"
      @handleSelect="updateCurrentPreset"
    />
    <!-- Preset actions -->
    <div class="flex items-center gap-1">
      <button
        :disabled="!hasSelectedPreset && getIsCustomPreset"
        class="preset-btn px-2.5 py-1.5 rounded-md transition-colors"
        @click="showEditModal = true"
      >
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted truncate"
        >
          Edit
        </BaseText>
      </button>
      <!-- Divider -->
      <div class="border-l border-border-hover h-3" />
      <button
        class="preset-btn px-2.5 py-1.5 rounded-md transition-colors"
        @click="handleNewDerivedPreset"
      >
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted whitespace-nowrap"
        >
          Save new Preset
        </BaseText>
      </button>
      <!-- Divider -->
      <div class="border-l border-border-hover h-3" />
      <button
        class="preset-btn px-2.5 py-1.5 rounded-md transition-colors"
        :disabled="!canUpdatePreset || (!hasSelectedPreset && getIsCustomPreset)"
        @click="handlePresetSave(getLocalPreset)"
      >
        <div
          v-if="loading.updatePreset"
          class="w-24 text-center"
        >
          <BaseLoadingSpinnerCircle class="mx-auto" />
        </div>
        <BaseText
          v-else
          type="label"
          size="sm"
          class="whitespace-nowrap"
        >
          Update Preset
        </BaseText>
      </button>
    </div>
    <!-- Modals -->
    <EditPresetModal
      v-if="showEditModal"
      key="edit-preset"
      @close="handleModalClose"
      @save="handlePresetSave"
      @delete="handlePresetDelete"
    />
    <EditPresetModal
      v-if="showCreateModal"
      key="new-preset"
      is-new
      :should-inherit="shouldInheritColumns"
      @close="handleModalClose"
      @save="handleNewPreset"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { mixin as clickaway } from 'vue-clickaway2'
import LensAPI from '@/api/lens'
// Components
import SelectedPresetDropdown from '../SelectedPresetDropdown.vue'
import EditPresetModal from './EditPresetModal.vue'

export default {
  name: 'ManagePresetHeader',
  components: {
    EditPresetModal,
    SelectedPresetDropdown
  },
  mixins: [clickaway],
  props: {
    hasSelectedPreset: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      showEditModal: false,
      showCreateModal: false,
      isExpanded: false,

      shouldInheritColumns: false,

      loading: {
        updatePreset: false
      }

    }
  },
  computed: {
    ...mapGetters('LensModule', [
      'getLocalPreset',
      'getSelectedPreset',
      'getPresets',
      'getIsCustomPreset'
    ]),
    canUpdatePreset () {
      const originalPreset = this.getPresets.find(
        (preset) => preset.id === this.getLocalPreset.id
      )
      return (!this.hasSelectedPreset && this.getIsCustomPreset) ? false : !_.isEqual(
        originalPreset?.table_columns,
        this.getLocalPreset?.table_columns
      )
    }
  },
  methods: {
    ...mapActions('LensModule', [
      'updatePreset',
      'setPresetById',
      'fetchPresets'
    ]),
    async handlePresetSave (toUpdate) {
      this.loading.updatePreset = true
      try {
        await this.updatePreset(toUpdate)
        this.$showAlert({ type: 'success', message: 'Preset successfully updated' })
        this.loading.updatePreset = false
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Could not update preset' })
        this.loading.updatePreset = false
        console.error(e)
      }
    },
    handleModalClose () {
      this.showEditModal = false
      this.showCreateModal = false
      // Reset on close, init on open
      this.shouldInheritColumns = false
    },
    handleNewDerivedPreset () {
      this.shouldInheritColumns = true
      this.showCreateModal = true
    },
    updateCurrentPreset (presetId) {
      this.setPresetById({ id: presetId })
      this.$emit('selectPreset', presetId)
    },
    async handleNewPreset (toSave) {
      try {
        const newPreset = {
          ...toSave,
          table_columns: this.shouldInheritColumns
            ? this.getLocalPreset?.table_columns || []
            : []
        }
        const data = await LensAPI.LensPreset.create(newPreset)
        const newPresetId = data.presetId
        await this.fetchPresets()
        this.setPresetById({ id: newPresetId })

        this.$showAlert({
          type: 'success',
          message: 'Preset created successfully'
        })
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    },
    async handlePresetDelete (presetId) {
      try {
        await LensAPI.LensPreset.remove(presetId)
        await this.fetchPresets()
        if (this.getLocalPreset.id === presetId) {
          this.setPresetById({ id: null })
        }
        this.$showAlert({
          type: 'success',
          message: 'Preset deleted successfully'
        })
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    }
  }
}
</script>

<style scoped>
.preset-tag {
  box-shadow: 0px 0px 0.25px 0.75px rgba(6, 7, 15, 0.02),
    0px 1.5px 1.5px -0.75px rgba(6, 7, 15, 0.08),
    0px 3px 3px -1.5px rgba(6, 7, 15, 0.04);
}

.preset-btn{
  color: #303546;
}
.preset-btn:not(:disabled):hover{
  background-color:#eceff3
}
.preset-btn:disabled{
  cursor: not-allowed;
  color:#a4abb8;
  opacity: 0.5;
}
</style>
