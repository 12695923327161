<template>
  <div
    class="flex items-center gap-2 h-11"
  >
    <!-- TODO @Sam: sync with tags prop once functionality in -->
    <div class="p-0.5 pl-2 bg-neutral-25 rounded-md flex items-center gap-1">
      <BaseText
        type="label"
        size="sm"
        class="whitespace-nowrap truncate"
      >
        Tag 1
      </BaseText>
      <TagRemoveIcon @click="removeTag" />
    </div>
    <div
      v-if="isMultiple"
      class="px-2 py-1 rounded-md border border-border-normal"
    >
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted"
      >
        +{{ tags.length -1 }}
      </BaseText>
    </div>
  </div>
</template>

<script>
import TagRemoveIcon from '../../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'TagCell',
  components: {
    TagRemoveIcon
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  computed: {
    isMultiple () {
      return this.tags.length > 1
    }
  },
  methods: {
    removeTag () {
      this.$emit('removeTag')
    }
  }
}
</script>
