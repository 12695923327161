<template>
  <BaseModal
    card-width="800px"
    no-padding
    no-toolbar
    no-exit
    @close="$emit('close')"
  >
    <template #default>
      <div class="share-container bg-background-normal p-1">
        <!-- Share list (left-col) -->
        <div class="w-80 bg-background-normal p-3 rounded-l-xl flex flex-col justify-between">
          <div class="p-1.5 flex items-center gap-1.5">
            <ExternalShareIcon />
            <BaseText
              type="label"
              size="sm"
            >
              Shares
            </BaseText>
          </div>

          <!-- Share links -->
          <div
            v-if="pastReportShares.length > 0"
            class="flex flex-col gap-2 share-list scroll-area p-1"
          >
            <div
              v-for="pastShare in pastReportShares"
              :key="pastShare.id"
              class="flex items-center gap-2 py-1.5 pl-1.5 pr-2 hover:bg-background-hover cursor-pointer w-full rounded-md"
              :class="currentViewableShare?.id === pastShare?.id ? 'base-btn' : ''"
              @click="setShareView(modalViews.OLD_SHARE, pastShare)"
            >
              <StaticReportIcon class="text-icon-normal" />
              <div class="flex flex-1 items-center gap-1">
                <BaseText
                  class="text-text-muted flex"
                  type="label"
                  size="sm"
                >
                  {{ formatDate(pastShare.created_at) }}
                </BaseText>
                <div
                  class="w-1 h-1 rounded-full"
                  :class="pastShare?.is_published ? 'bg-secondary-green-100' : 'bg-icon-disabled'"
                />
              </div>
              <LockedIcon
                v-if="pastShare.is_password_locked"
                class="text-icon-normal"
              />
              <div
                class="flex items-center gap-1"
              >
                <PreviewIcon class="text-icon-normal" />
                <BaseText
                  class="text-text-normal"
                  size="sm"
                  type="label"
                >
                  {{ pastShare?.view_count }}
                </BaseText>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center my-4"
          >
            <BaseText
              class="text-text-subdued"
              type="label"
              size="sm"
            >
              No past share links
            </BaseText>
          </div>
          <!-- Generate new share -->
          <div
            class=" flex items-center gap-2 text-text-muted p-1.5 hover:bg-background-hover cursor-pointer w-full rounded-md transition-colors"
            @click="setShareView(modalViews.NEW_SHARE, null)"
          >
            <PlusIcon />
            <BaseText
              type="label"
              size="sm"
            >
              New Share Link
            </BaseText>
          </div>
        </div>
        <!-- Share details - right col -->
        <div class="share-form scroll-area">
          <ShareReportForm
            v-if="view === modalViews.NEW_SHARE"
            :share-form="shareForm"
            :enable-form="enableForm"
            :loading="loading"
            @formChange="updateForm"
            @configChange="updateConfig"
            @createShare="handleGenerateShare"
          />
          <EditShareReport
            v-else
            :current-share="currentViewableShare"
            :loading="loading"
            @update="handleShareUpdate"
            @delete="handleShareDelete"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import LensAPI from '@/api/lens'
import FirebaseAPI from '@/api/firebase'
import _ from 'lodash'
import { mapGetters } from 'vuex'
// Components
import ShareReportForm from './ShareReportForm.vue'
import EditShareReport from './EditShareReport.vue'

// Icons
import ExternalShareIcon from '../../../globals/Icons/ExternalShareIcon.vue'
import StaticReportIcon from '../../../globals/Icons/LensIcons/report/StaticReportIcon.vue'
import LockedIcon from '../../../globals/Icons/LockedIcon.vue'
import PreviewIcon from '../../../globals/Icons/PreviewIcon.vue'
import PlusIcon from '../../../globals/Icons/PlusIcon.vue'
export default {
  name: 'ShareReportModal',
  components: {
    // Components
    ShareReportForm,
    EditShareReport,
    // Icons
    ExternalShareIcon,
    StaticReportIcon,
    LockedIcon,
    PreviewIcon,
    PlusIcon
  },
  props: {
    currentGraph: {
      type: String,
      default: () => ''
    },
    report: {
      type: Object,
      default: () => {}
    },
    lensId: {
      type: [Number, String],
      default: () => ''
    },
    reportId: {
      type: String,
      default: () => ''
    },
    summaryStats: {
      type: Object,
      default: () => {}
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    currentDateRange: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const modalViews = {
      NEW_SHARE: 'newShare',
      OLD_SHARE: 'oldShare'
    }
    return {
      modalViews,
      view: modalViews.NEW_SHARE,

      fallbackAvatar: require('../../../../assets/images/defaultProfileImage.webp'),

      currentViewableShare: null,

      pastReportShares: [],

      loading: {
        createShare: false,
        delete: false,
        edit: false
      },

      shareForm: {
        name: this.report.name,
        description: this.report.description,
        password: '',
        // TODO @Sam: leave as editable for now, but probably will remove later
        dateRange: { ...this.currentDateRange },
        type: 'static'
      },

      enableForm: {
        password: false,
        trackIps: false,
        showComments: false,
        isPublished: false
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  },
  async mounted () {
    this.pastReportShares = (await LensAPI.LensReport.getAllShares(this.reportId)).data
  },
  methods: {
    async handleShareUpdate (id, toUpdate) {
      this.loading.edit = true
      try {
        const updatedShare = await LensAPI.LensReport.updateShare(id, toUpdate)
        this.currentViewableShare = {
          ...updatedShare,
          created_by: this.currentViewableShare.created_by
        }
        this.pastReportShares = this.pastReportShares.map((pastShare) => pastShare.id === id ? updatedShare : pastShare)
        this.$showAlert({ type: 'success', message: 'Share link updated' })
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Error updating share link' })
      } finally {
        this.loading.edit = false
      }
    },
    async handleShareDelete (id) {
      this.loading.delete = true
      try {
        await LensAPI.LensReport.deleteShare(id)
        this.pastReportShares = this.pastReportShares.filter((pastShare) => pastShare.id !== id)
        this.setShareView(this.modalViews.NEW_SHARE, null)
        this.$showAlert({ type: 'success', message: 'Share link successfully deleted' })
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Error deleting share link' })
      } finally {
        this.loading.delete = false
      }
    },
    formatDate (timestamp) {
      const date = new Date(timestamp * 1000)
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
    },
    async setShareView (view, shareData) {
      // Share data is null on new share view
      if (shareData) {
        const createdBy = await this.getShareCreatedBy(shareData.created_by)
        this.currentViewableShare = {
          ...shareData,
          created_by: {
            avatar: createdBy?.avatar ?? this.fallbackAvatar,
            name: createdBy?.name ?? `${createdBy?.first_name} ${createdBy?.last_name}`
          }
        }
        this.enableForm = {
          password: !!shareData?.access_password,
          trackIps: shareData?.is_tracking,
          showComments: shareData?.is_comments_visible,
          isPublished: shareData?.is_published
        }
      } else {
        this.currentViewableShare = null
        this.shareForm.password = ''
        this.enableForm = {
          password: false,
          trackIps: false,
          showComments: false,
          isPublished: false
        }
      }
      this.view = view
    },
    // Query for user data on demand - memoize to prevent requeries
    getShareCreatedBy: _.memoize(async function (uid) {
      return await FirebaseAPI.Users.getById(uid)
    }),
    async handleGenerateShare () {
      this.loading.createShare = true
      const reportCols = this.report?.table_columns
      // TODO @Sam: remove once we update backend to only provide necessary kpis
      const minimalSnapshot = this.selectedRows.map((group) => {
        const percentDeviation = {}
        const adObj = {
          group_img_url: group.ads?.[0].ad_image_url,
          group_by: group.group_by,
          ads: [],
          active_ad_count: group.active_ad_count,
          inactive_ad_count: group.inactive_ad_count,
          paused_ad_count: group.paused_ad_count
        }
        reportCols.forEach((col) => {
          const key = col.key
          adObj[key] = group[key]
          percentDeviation[key] = group.percent_deviation[key]
        })
        group.ads.forEach((ad, i) => {
          adObj.ads.push({ ad_image_url: i <= 10 ? ad.ad_image_url : '' })
        })
        return {
          ...adObj,
          percent_deviation: {
            ...percentDeviation
          }
        }
      })
      console.log('snapshot', minimalSnapshot)
      const sharePayload = {
        name: this.shareForm.name,
        description: this.shareForm.description,
        report_id: this.reportId,
        lens_id: this.lensId,
        team_id: this.getTeam?.id ?? null,
        type: this.shareForm.type,
        is_comments_visible: this.enableForm.showComments,
        is_tracking: this.enableForm.trackIps,
        is_published: true,
        access_password: this.shareForm.password ?? null,
        is_password_locked: this.enableForm.password,
        // Report/Ad group relevant data
        query: {
          snapshot: minimalSnapshot,
          group_by: this.report?.group_by,
          table_columns: this.report?.table_columns,
          table_config: this.report?.table_config,
          selected_columns: this.report?.selected_columns[this.report?.graph_type],
          graph_type: this.report?.graph_type,
          summary_stats: this.summaryStats,
          sorted_column: this.report?.sorted_column,
          date_range: this.shareForm.dateRange
        }
        // Created_by is generated on backend
      }
      try {
        const { data: sharedReport } = await LensAPI.LensReport.createShare(sharePayload)
        this.pastReportShares = (await LensAPI.LensReport.getAllShares(this.reportId)).data
        console.log('%c GENERATED SHARE REPORT', 'color: orange; font-size: 18px;')
        this.setShareView(this.modalViews.OLD_SHARE, sharedReport)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading.createShare = false
      }
    },
    updateForm (change) {
      console.log('chage', change)
      const { type, value } = change
      this.shareForm[type] = value
    },
    updateConfig (change) {
      const { type, value } = change
      this.enableForm[type] = value
    }
  }
}
</script>

<style scoped>
.share-container{
  border-radius: 20px;
  display: grid;
  width: 100%;
  grid-template-columns: 320px 1fr;
}
.share-list{
  height: 445px;
  overflow-y:auto;
  padding-right: 4px;
}
.scroll-area{
  scroll-margin-right: 4px;
}
.scroll-area::-webkit-scrollbar {
  width: 6px;
}
.scroll-area::-webkit-scrollbar-thumb {
  background-color: #dfe1e7;
  border-radius: 18px;

}
.scroll-area::-webkit-scrollbar-thumb:hover {
  background-color: #bcbec2
}

.share-form{
  height: 565px;
  overflow-y:auto;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.08), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.12), 0px 0px 0.75px 0.75px rgba(6, 7, 16, 0.04);
  box-shadow: 0px 3px 3px -1.5px color(display-p3 0.0235 0.0275 0.0588 / 0.08), 0px 1.5px 1.5px -0.75px color(display-p3 0.0235 0.0275 0.0588 / 0.12), 0px 0px 0.75px 0.75px color(display-p3 0.0235 0.0275 0.0588 / 0.04);
}
.submit-btn:disabled{
  box-shadow:none;
  background-color:#F6F8FA;
  color: #A4acb9;
  cursor: not-allowed
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.invalid-dropdown{
  box-shadow: none;
  border: 1px solid #FF002F;
}
.form-input.invalid {
  box-shadow: none;
  border: 1px solid #FF002F;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}

/* Section transitions */
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition-duration: 200ms;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}
</style>
