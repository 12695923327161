<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M11.5764 6.88114C11.9135 7.53089 12.5924 7.9748 13.375 7.9748C14.4934 7.9748 15.4 7.06818 15.4 5.9498C15.4 4.83143 14.4934 3.9248 13.375 3.9248C12.2566 3.9248 11.35 4.83143 11.35 5.9498C11.35 6.28561 11.4317 6.60233 11.5764 6.88114ZM11.5764 6.88114L7.74858 9.06844M7.74858 9.06844C7.41144 8.41872 6.73256 7.9748 5.94999 7.9748C4.83161 7.9748 3.92499 8.8814 3.92499 9.9998C3.92499 11.1182 4.83161 12.0248 5.94999 12.0248C6.73256 12.0248 7.41144 11.5809 7.74858 10.9312M7.74858 9.06844C7.89325 9.34728 7.97499 9.66399 7.97499 9.9998C7.97499 10.3356 7.89325 10.6523 7.74858 10.9312M7.74858 10.9312L11.5764 13.1184M11.5764 13.1184C11.4317 13.3973 11.35 13.714 11.35 14.0498C11.35 15.1682 12.2566 16.0748 13.375 16.0748C14.4934 16.0748 15.4 15.1682 15.4 14.0498C15.4 12.9314 14.4934 12.0248 13.375 12.0248C12.5924 12.0248 11.9135 12.4687 11.5764 13.1184Z"
        stroke="#303546"
        style="stroke:#303546;stroke:color(display-p3 0.1882 0.2078 0.2745);stroke-opacity:1;"
        stroke-width="1.35"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExternalShareIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
