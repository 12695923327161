<template>
  <div class="grid grid-cols-12 gap-x-3 p-3">
    <button
      class="col-span-2 max-w-sm h-8"
      :class="[{'field-container': !enable.edit}]"
      @click="editGroupName"
    >
      <input
        :ref="`group-name-${group.id}`"
        v-model="groupName"
        :disabled="!enable.edit"
        placeholder="Group name"
        class="truncate form-input fade-in min-w-0 w-full px-2 py-1.5"
        :class="[{'preview-input': !enable.edit, 'invalid-input' : isNameConflicting}]"
        @blur="handleNameChange"
      >
    </button>
    <div class="flex items-center gap-2 flex-wrap col-span-6">
      <BaseText
        size="sm"
        class="text-text-normal"
      >
        where
      </BaseText>
      <div
        v-for="(combinedCondition,index) in group.conditions"
        :ref="`${group.id}-applied-filter-${index}`"
        :key="`${group.id}-applied-filter-${index}`"
        v-on-clickaway="() => { if (currentEditKey === getGroupKey(index)) $emit('toggleEdit', null) }"
        class="relative"
      >
        <AppliedFilter
          :filter-conditions="combinedCondition"
          :is-editing="currentEditKey === getGroupKey(index)"
          @selected="$emit('toggleEdit', getGroupKey(index))"
          @remove="handleFilterRemoved(index)"
        />
        <transition>
          <div
            v-if="currentEditKey === getGroupKey(index)"
            class="edit-filter-query-builder"
            :class="getEditFilterDropdownPositionClasses(index)"
          >
            <LensFilterQueryBuilder
              :init-filter-conditions="combinedCondition"
              @createFilter="handleEditAppliedFilter($event, index)"
              @close="$emit('toggleEdit', null)"
            />
          </div>
        </transition>
      </div>
      <div
        v-on-clickaway="closeFilterCreation"
        class="relative"
      >
        <button
          class="flex hover:bg-background-normal items-center p-1 rounded-md transition-colors duration-100"
          @click="show.filterDropdown = !show.filterDropdown"
        >
          <PlusIcon class="h-4" />
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted pl-1 py-0.5 pr-2"
          >
            Add Filter
          </BaseText>
        </button>
        <!-- Filter Type Selector Dropdown -->
        <transition>
          <div
            v-if="show.filterDropdown"
            class="filter-selector-dropdown"
          >
            <PropertySelectDropdown
              search-placeholder="Search Filters"
              @optionSelected="handleFilterOptionSelected"
              @close="closeFilterCreation"
            />
          </div>
        </transition>
        <!-- Filter Query Builder -->
        <transition>
          <div
            v-if="builderInitFilterOption"
            class="filter-query-builder"
          >
            <LensFilterQueryBuilder
              :init-filter-option="builderInitFilterOption"
              @createFilter="handleFilterCreated"
              @close="closeFilterCreation"
            />
          </div>
        </transition>
      </div>
    </div>
    <div
      class="col-span-4 flex items-center gap-3 justify-self-end"
    >
      <div
        class="flex items-center gap-1 group cursor-pointer p-1 rounded-md"
        @click="$emit('showBreakdown', group.name)"
      >
        <div
          v-if="adInfo?.[group.name]"
          class="flex items-center gap-1"
        >
          <BaseText
            size="sm"
            class="text-text-subdued group-hover:text-text-muted"
          >
            {{ adInfo[group.name].adCount }} Ads
          </BaseText>
          <ChevronIcon class="text-icon-disabled group-hover:text-icon-normal transform -rotate-90" />
        </div>
        <div
          v-if="adInfo?.[group.name]"
          class="flex items-center gap-1"
        >
          <BaseText
            size="sm"
            class="text-text-subdued group-hover:text-text-muted"
          >
            {{ adInfo[group.name].spentCount }} Spent
          </BaseText>
          <ChevronIcon class="text-icon-disabled group-hover:text-icon-normal  transform -rotate-90" />
        </div>
      </div>
      <div class="flex items-center gap-1">
        <button
          class="p-1 hover:bg-background-normal rounded-md transition-colors"
          @click="handleGroupDupe"
        >
          <StaticCopyIcon class="text-icon-normal" />
        </button>
        <button
          class="p-1 hover:bg-background-normal rounded-md transition-colors"
          @click="handleGroupRemove"
        >
          <DeleteIcon class="text-icon-normal" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import _ from 'lodash'
// Components
import PropertySelectDropdown from '../filters/PropertySelectDropdown.vue'
import LensFilterQueryBuilder from '../filters/LensFilterQueryBuilder.vue'
import AppliedFilter from '../filters/AppliedFilter.vue'
// Icons
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import StaticCopyIcon from '../../globals/Icons/StaticCopyIcon.vue'
export default {
  name: 'CustomGroup',
  components: {
    // Components
    PropertySelectDropdown,
    LensFilterQueryBuilder,
    AppliedFilter,
    // Icons
    PlusIcon,
    ChevronIcon,
    DeleteIcon,
    StaticCopyIcon
  },
  mixins: [clickaway],
  props: {
    group: {
      type: Object,
      default: () => {}
    },
    adInfo: {
      type: Object,
      default: () => {}
    },
    currentEditKey: {
      type: [String, null],
      default: null
    },
    existingNames: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      groupName: '',

      builderInitFilterOption: null,
      submitAttempted: false,
      show: {
        filterDropdown: false
      },

      enable: {
        edit: false
      }
    }
  },
  computed: {
    isNameConflicting () {
      const isExistingName = this.existingNames.some(existingGroup => existingGroup.id !== this.group.id && existingGroup.name === this.groupName)
      return isExistingName || (this.submitAttempted && !this.groupName)
    }
  },
  mounted () {
    this.groupName = this.group.name
  },
  methods: {
    getGroupKey (index) {
      return `${this.group.id}-applied-filter-${index}`
    },
    editGroupName () {
      if (!this.enable.edit) {
        this.enable.edit = true
        const inputRef = this.$refs[`group-name-${this.group.id}`]
        this.$nextTick(() => {
          if (inputRef) {
            inputRef.focus()
          }
        })
      }
    },
    handleGroupRemove () {
      const preventFetch = this.group.conditions.length === 0
      this.$emit('delete', this.group.id, preventFetch)
    },
    handleGroupDupe () {
      this.$emit('duplicate', this.group.id)
    },
    handleNameChange (event) {
      this.submitAttempted = true
      if (this.isNameConflicting) return
      this.enable.edit = false
      const newName = event.target.value
      const groupId = this.group.id
      const preventFetch = this.group.name === newName || this.group.conditions.length === 0
      this.$emit('changeCondition', { id: groupId, toUpdate: { name: newName }, preventFetch })
      this.groupName = newName
    },
    closeFilterCreation () {
      this.show.filterDropdown = false
      this.builderInitFilterOption = null
    },
    handleEditAppliedFilter (filterConditions, index) {
      const newFilters = this.group.conditions
      newFilters[index] = filterConditions
      const groupId = this.group.id
      this.$emit('changeCondition', { id: groupId, toUpdate: { conditions: newFilters } })
    },
    handleFilterRemoved (index) {
      const newFilters = this.group?.conditions.filter((_, i) => i !== index)
      const groupId = this.group.id
      this.$emit('changeCondition', { id: groupId, toUpdate: { conditions: newFilters } })
    },
    handleFilterCreated (filterConditions) {
      const newFilters = [...this.group?.conditions, filterConditions]
      const groupId = this.group.id
      const groupName = this.group.name || `Untitled group - ${filterConditions?.[0]?.value}`
      this.$emit('changeCondition', { id: groupId, toUpdate: { ...(this.group.name?.length === 0 && { name: groupName }), conditions: newFilters } })
    },
    handleFilterOptionSelected (opt) {
      this.builderInitFilterOption = opt
      this.show.filterDropdown = false
    },
    getEditFilterDropdownPositionClasses (index) {
      // Determines whether to left-align, center, or right-align the edit filter dropdown
      const filterElement = this.$refs[`${this.group.id}-applied-filter-${index}`][0]
      const mainLayoutParent = document.querySelector('.main-layout')
      if (!filterElement || !mainLayoutParent) return 'left-0'

      const mainLayoutRect = mainLayoutParent.getBoundingClientRect()
      const filterElmRect = filterElement.getBoundingClientRect()
      const filterElmPos = (filterElmRect.left - mainLayoutRect.left) + (filterElmRect.width / 2)

      const sectorWidth = mainLayoutRect.width / 3
      const sectorBoundaries = [sectorWidth, sectorWidth * 2]

      if (filterElmPos < sectorBoundaries[0]) {
        return 'left-0'
      } else if (filterElmPos < sectorBoundaries[1]) {
        return 'left-1/2 transform -translate-x-1/2'
      } else {
        return 'right-0'
      }
    }
  }
}
</script>

<style>
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
  height: 32px;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.preview-input{
  box-shadow: unset;
  outline: none;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
}

.invalid-input{
  box-shadow: none;
  border: 1px solid #ed8296;
}

.field-container{
  outline: none;
  box-shadow: unset;
  background-color: white;
  border-radius: 6px;
  transition: background-color 150ms ease-in-out
}

.field-container:hover{
  background-color: #F6F8FA;
}

.filter-selector-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index:500;
}

.filter-query-builder {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 500
}

.edit-filter-query-builder {
  position: absolute;
  top: calc(100% + 4px);
  z-index: 500
}

</style>
