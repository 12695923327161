<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.74976 14.25V5.75C2.74976 4.09315 4.0929 2.75 5.74976 2.75H9.99976M2.74976 14.25C2.74976 15.9069 4.0929 17.25 5.74976 17.25H14.2498C15.9066 17.25 17.2498 15.9069 17.2498 14.25M2.74976 14.25V13.75M17.2498 14.25V13.75M17.2498 14.25V10"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7895 1.57896C15.636 1.27186 15.1977 1.27186 15.0442 1.57896L14.2289 3.20938C14.1887 3.29002 14.1233 3.3554 14.0426 3.39572L12.4122 4.21092C12.1051 4.36447 12.1051 4.80273 12.4122 4.95628L14.0426 5.77149C14.1233 5.81181 14.1887 5.87719 14.2289 5.95783L15.0442 7.58825C15.1977 7.89535 15.636 7.89535 15.7895 7.58825L16.6048 5.95783C16.645 5.87719 16.7104 5.81181 16.7911 5.77149L18.4215 4.95628C18.7286 4.80273 18.7286 4.36447 18.4215 4.21092L16.7911 3.39572C16.7104 3.3554 16.645 3.29002 16.6048 3.20938L15.7895 1.57896Z"
      fill="currentColor"
    />
    <path
      d="M6.49976 10.5422V13.9172M9.87476 6.49219V13.9172M13.2498 11.8922V13.9172"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'GroupDetailsStaticIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
