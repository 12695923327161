import { render, staticRenderFns } from "./CustomMetricIcon.vue?vue&type=template&id=04c8973a&scoped=true"
import script from "./CustomMetricIcon.vue?vue&type=script&lang=js"
export * from "./CustomMetricIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c8973a",
  null
  
)

export default component.exports