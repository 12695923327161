
<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M16 9.25V10C16 10.8284 15.3284 11.5 14.5 11.5H13C12.1716 11.5 11.5 12.1716 11.5 13V14.5C11.5 15.3284 10.8284 16 10 16H9.25M5.5 4H14.5C15.3284 4 16 4.67157 16 5.5V11.2537C16 11.6515 15.842 12.033 15.5606 12.3144L12.3144 15.5606C12.033 15.842 11.6515 16 11.2537 16H5.5C4.67157 16 4 15.3284 4 14.5V5.5C4 4.67157 4.67157 4 5.5 4Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoteIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
