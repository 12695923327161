<template>
  <button class="flex items-center gap-1 pl-2.5 py-1 pr-1 rounded-md transition-colors duration-100"
  :class="isEditing ? 'box-container-active' : 'box-container'"
  @click="$emit('selected')">
    <div v-for="(condition, index) in filterConditions" :key="condition.renderKey" class="flex items-center">
      <BaseText type="label" size="sm" class="text-text-muted mr-1 whitespace-nowrap">
        {{ condition.property.name }}
      </BaseText>
      <BaseText type="label" size="sm" class="text-text-normal bg-background-normal mr-1 whitespace-nowrap px-1.5 py-0.5 rounded-md">
        {{ condition.operation.name }}
      </BaseText>
      <BaseText type="label" size="sm" class="value-text text-text-muted">
        {{ getConditionValueText(condition) }}
      </BaseText>
      <BaseText v-if="index < filterConditions.length - 1" type="label" size="sm" class="text-text-normal bg-background-normal mx-2 whitespace-nowrap px-1.5 py-0.5 rounded-md">
        or
      </BaseText>
    </div>
    <button class="w-6 h-6 flex items-center justify-center transition-colors duration-100 hover:bg-neutral-25"
    style="border-radius: 4px;" @click.stop="$emit('remove')">
      <TagRemoveIcon class="text-icon-normal" :width="18" :height="18" />
    </button>
  </button>
</template>

<script>
import { format } from 'd3-format'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'AppliedFilter',
  components: {
    TagRemoveIcon
  },
  props: {
    filterConditions: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getConditionValueText(condition) {
      if (condition.property.input.type === 'dropdown') {
        return condition.property.input.options.find(option => option.value === condition.value)?.name || condition.value
      }
      return this.formatConditionValue(condition.value, condition.property?.input?.type || 'text')
    },
    formatConditionValue(value, type) {
      if (type === 'currency') {
        const hasDecimal = value.toString().includes('.')
        const fixedValue = value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: hasDecimal ? 2 : 0
        })
        return `$${fixedValue}`
      } else if (type === 'number') {
        return value.toLocaleString()
      } else if (type === 'percent') {
        const formattedDecimal = new Intl.NumberFormat(navigator?.language || 'en-US', {
          style: 'decimal',
          maximumFractionDigits: 5
        }).format(value)
        return `${formattedDecimal}%`
      } else if (type === 'text') {
        return `"${value}"`
      }
      return value
    }
  }
}
</script>

<style scoped>
.value-text {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-container{
  box-shadow: 0px 0px 0px 1px #12376914, 0px 1px 2px 0px #12376914;
}
.box-container:hover,
.box-container-active {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
</style>
