<template>
  <BaseModal
    card-width="400px"
    no-padding
    no-toolbar-padding
    no-exit
    @close="$emit('close')"
  >
    <template #toolbar>
      <div
        v-if="!createdNewReport"
        class="max-w-xs flex items-center gap-1"
      >
        <StaticCopyIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Duplicate to Another Lens
        </BaseText>
      </div>
      <div
        v-else
        class="flex items-center gap-2"
      >
        <SuccessPartyIcon />
        <BaseText
          class="text-text-muted"
          size="sm"
          type="label"
        >
          Done! Your report is ready.
        </BaseText>
      </div>
      <button
        class="absolute top-3 right-3 p-1.5"
        @click="$emit('close')"
      >
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>
    <template #default>
      <BaseHeightTransition>
        <!-- MODAL SECTION - Duplicate report details -->
        <div
          v-if="!createdNewReport"
          key="createReport"
          class="w-full flex flex-col items-stretch"
        >
          <!-- Modal body -->
          <div class="w-full h-full p-5 border-t border-b mt-4 flex flex-col gap-4">
            <!-- Ad Account -->
            <div class="w-full space-y-1">
              <BaseSingleDropdown
                label="Lens Account"
                placeholder="Select a lens"
                class="z-30 relative"
                show-label-space
                enable-search
                search-placeholder="Search Lenses"
                :selected-obj="dupeForm.lensId"
                :trigger-class="showError.lens ? 'invalid-dropdown' : ''"
                :options="otherLensAccounts"
                use-parent-width
                :option-label="(opt) => opt.name"
                @change="($event) => dupeForm.lensId = $event"
              />
              <div
                v-show="showError.lens"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <!-- Name -->
            <div class="space-y-1">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Report Name
              </BaseText>

              <input
                v-model="dupeForm.name"
                :class="{ 'invalid': showError.name }"
                placeholder="Report name"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
              <!-- invalid indicator -->
              <div
                v-show="showError.name"
                class="flex items-center gap-1"
              >
                <AltAlertIcon class="text-secondary-red-100" />
                <BaseText
                  type="body"
                  size="xs"
                  class="text-secondary-red-100"
                >
                  Required
                </BaseText>
              </div>
            </div>
            <!-- Description -->
            <div class="space-y-1">
              <div class="flex items-center gap-1">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Description
                </BaseText>
                <BaseText
                  class="text-text-subdued"
                  size="xs"
                >
                  (Optional)
                </BaseText>
              </div>
              <input
                v-model="dupeForm.description"
                placeholder="Type a description of this report"
                class="form-input fade-in min-w-0 w-full px-2 py-1.5"
              >
            </div>
          </div>
          <!-- Modal footer -->
          <div class="w-full flex justify-between items-center p-5">
            <BaseButton
              class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
              @click="$emit('close')"
            >
              Cancel
            </BaseButton>
            <button
              class="black-btn-primary submit-btn py-1.5 px-3 rounded-md flex items-center justify-center text-white"
              style="width: 88px"
              :disabled="loading.submit"
              @click="duplicateReport"
            >
              <BaseLoadingSpinnerCircle
                v-if="loading.submit"
              />

              <BaseText
                v-else
                type="label"
                size="sm"
              >
                Duplicate
              </BaseText>
            </button>
          </div>
        </div>
        <!-- MODAL SECTION - Duplicate report success -->
        <div
          v-else
          key="successReport"
        >
          <div
            class="px-5 pb-5 pt-2 border-b"
          >
            <BaseText
              size="sm"
              class="text-neutral-500"
            >
              Your report has been successfully duplicated and is ready for use.
            </basetext>
          </div>
          <!-- Modal footer -->
          <div class="w-full flex justify-between items-center p-5">
            <BaseButton
              class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
              @click="$emit('close')"
            >
              Close
            </BaseButton>
            <button
              class="black-btn-primary py-1.5 px-3 rounded-md flex items-center justify-center text-white"
              @click="navToReport"
            >
              <BaseLoadingSpinnerCircle
                v-if="loading.submit"
              />

              <BaseText
                v-else
                type="label"
                size="sm"
              >
                Open Duplicated Report
              </BaseText>
            </button>
          </div>
        </div>
      </BaseHeightTransition>
    </template>
  </BaseModal>
</template>

<script>
import LensAPI from '@/api/lens'
// Icons
import StaticCopyIcon from '../../globals/Icons/StaticCopyIcon.vue'
import AltAlertIcon from '../../globals/Icons/AltAlertIcon.vue'
import SuccessPartyIcon from '../../globals/Icons/SuccessPartyIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
export default {
  name: 'DuplicateReportModal',
  components: {
    StaticCopyIcon,
    AltAlertIcon,
    SuccessPartyIcon,
    TagRemoveIcon
  },
  props: {
    report: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // Modal state
      createdNewReport: false,

      // options
      otherLensAccounts: [],

      // Form
      submitAttempted: false,
      dupeForm: {
        lensId: null,
        name: '',
        description: ''
      },
      loading: {
        lensAccounts: false,
        submit: false
      }
    }
  },
  computed: {
    showError () {
      return {
        lens: !this.dupeForm.lensId?.id && this.submitAttempted,
        name: !this.dupeForm.name && this.submitAttempted
      }
    },
    isFormValid () {
      return this.dupeForm.name && this.dupeForm.lensId?.id
    }
  },
  async mounted () {
    this.loading.lensAccounts = true

    // Init form with copied data
    if (this.report.name) { this.dupeForm.name = `${this.report.name} (Copy)` }

    try {
      const lensAccounts = await LensAPI.Lens.getAll()
      // TODO @Sam: verify if needed
      // const currentLensId = this.report?.lens_id
      // this.otherLensAccounts = lensAccounts.filter((lens) => lens.id !== currentLensId)
      this.otherLensAccounts = lensAccounts.data?.map((lens) => { return { id: lens.id, name: lens.company_name } })
    } catch (error) {
      // TODO @Sam: update later
      this.$showAlert({
        type: 'error',
        message: 'Could not fetch lens account, please try refreshing'
      })
      console.error('An error occurred while fetching lens accounts', error)
    } finally {
      this.loading.lensAccounts = false
    }
  },
  methods: {
    async duplicateReport () {
      this.submitAttempted = true
      if (!this.isFormValid) return
      this.loading.submit = true
      try {
        const duplicateReport = {
          name: this.dupeForm.name,
          description: this.dupeForm.description,
          lens_id: this.dupeForm.lensId.id,
          pinned_columns: this.report.pinned_columns,
          filters: this.report.filters,
          selected_columns: this.report.selected_columns,
          preset_id: this.report.preset_id,
          selected_graph_rows: this.report.selected_graph_rows,
          sorted_column: this.report.sorted_column,
          table_config: this.report.table_config,
          group_by: this.report.group_by,
          table_columns: this.report.table_columns
        }
        // Call the API to duplicate the report
        const { data } = await LensAPI.LensReport.create(duplicateReport)
        console.log('%c Report successfully duplicated', 'color: orange')
        this.newReportNav = {
          lensId: duplicateReport.lens_id,
          reportId: data.id
        }
        // console.log(newReport)
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Error duplicating report'
        })
      } finally {
        this.loading.submit = false
        this.createdNewReport = true
      }
    },
    navToReport () {
      const { lensId, reportId } = this.newReportNav
      // const reportUrl = `${process.env.VUE_APP_BASE_URL}/lens/${lensId}/${reportId}`
      this.$router.push({ name: 'LensReport', params: { lensId, reportId } })
    }
  }
}
</script>

<style scoped>
.submit-btn:disabled{
  box-shadow:none;
  background-color:#F6F8FA;
  color: #A4acb9;
  cursor: not-allowed
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.invalid-dropdown{
  box-shadow: none;
  border: 1px solid #FF002F;
}
.form-input.invalid {
  box-shadow: none;
  border: 1px solid #FF002F;
}

/* Section transitions */
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition-duration: 200ms;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}
</style>
