<template>
  <BaseModal
    card-width="400px"
    no-padding
    no-toolbar-padding
    no-exit
    @close="$emit('close')"
  >
    <template #toolbar>
      <div

        class="max-w-xs flex items-center gap-1"
      >
        <EditIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Edit Report
        </BaseText>
      </div>

      <button
        class="absolute top-3 right-3 p-1.5"
        @click="$emit('close')"
      >
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>
    <template #default>
      <div
        class="p-5 flex flex-col gap-3"
      >
        <!-- Name -->
        <div class="flex flex-col gap-1 w-full">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Report Name
          </BaseText>

          <input
            v-model="reportForm.name"
            placeholder="Creative Highlights"
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
        </div>
        <!-- Desc -->
        <div class="flex flex-col gap-1 w-full col-span-4">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Description
          </BaseText>

          <input
            v-model="reportForm.desc"
            placeholder="Type a description of this report"
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
        </div>
      </div>
      <div class="w-full flex justify-between items-center p-4 border-t border-border-normal">
        <BaseButton
          class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
          @click="$emit('close')"
        >
          Cancel
        </BaseButton>
        <button
          class="black-btn-primary submit-btn py-1.5 px-3 rounded-md flex items-center justify-center text-white"
          style="width: 88px"
          :disabled="loading.submit || !isFormChanged"
          @click="updateReport"
        >
          <BaseLoadingSpinnerCircle
            v-if="loading.submit"
          />

          <BaseText
            v-else
            type="label"
            size="sm"
          >
            Update
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import LensAPI from '@/api/lens'
// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import EditIcon from '../../globals/Icons/EditIcon.vue'
export default {
  name: 'EditReportModal',
  components: {
    TagRemoveIcon,
    EditIcon
  },
  props: {
    reportId: {
      type: String,
      default: () => ''
    },
    reportName: {
      type: String,
      default: () => ''
    },
    reportDesc: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      loading: {
        submit: false
      },
      reportForm: {
        name: '',
        desc: ''
      }
    }
  },
  computed: {
    isFormChanged () {
      return this.reportForm.name !== this.reportName || this.reportForm.desc !== this.reportDesc
    }
  },
  async mounted () {
    this.reportForm = {
      name: this.reportName,
      desc: this.reportDesc
    }
  },
  methods: {
    async updateReport () {
      this.loading.submit = true
      try {
        const payload = {
          name: this.reportForm.name, description: this.reportForm.desc
        }
        await LensAPI.LensReport.update(this.reportId, payload)
        this.$emit('update', payload)
        this.$showAlert({ type: 'success', message: 'Report successflly updated' })
      } catch (e) {
        this.$showAlert({ type: 'error', message: 'Unable to update this report' })
        this.$emit('close')
      } finally {
        this.loading.submit = false
      }
    }
  }
}
</script>

<style scoped>
.submit-btn:disabled{
  box-shadow:none;
  background-color:#F6F8FA;
  color: #A4acb9;
  cursor: not-allowed
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.invalid-dropdown{
  box-shadow: none;
  border: 1px solid #FF002F;
}
.form-input.invalid {
  box-shadow: none;
  border: 1px solid #FF002F;
}

/* Section transitions */
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition-duration: 200ms;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}
</style>
