<template>
  <div
    class="h-full"
    :style="{
      padding: noPadding ? '0' : '1rem',
      borderLeft: noBorder ? 'none' : '1px solid #eceff3'
    }"
  >
    <BaseText
      v-if="!hideTitle"
      type="label"
      size="sm"
    >
      Selected KPI's
    </BaseText>
    <div
      v-if="(pinnedColumns.length + includedColumns.length) > 0"
      :style="{
        marginTop: hideTitle ? '0' : '12px'
      }"
    >
      <div v-if="pinnedColumns.length > 0">
        <div
          v-for="pinnedKpi in pinnedColumns"
          :key="pinnedKpi.key"
          class="flex items-center gap-1 justify-between py-1 px-0.5"
        >
          <div
            class="flex gap-1 truncate"
          >
            <DragListIcon
              class="text-icon-disabled flex-shrink-0"
              :class="{ 'drag-handle cursor-pointer': !pinnedKpi.is_pinned }"
            />
            <BaseText
              class="text-text-muted capitalize flex-shrink truncate"
              type="label"
              size="sm"
            >
              {{ getMetricLookup[pinnedKpi.key]?.name || pinnedKpi }}
            </BaseText>
            <BaseTooltip
              v-if="pinnedKpi.is_pinned"
              placement="right"
              dark
            >
              <div
                slot="reference"
              >
                <PinIcon />
              </div>
              <div
                slot="content"
                class="px-2 w-max"
              >
                Unpin to re-order
              </div>
            </BaseTooltip>
          </div>
          <TagRemoveIcon
            class="cursor-pointer flex-shrink-0"
            @click="removeKPI(pinnedKpi, true)"
          />
        </div>
      </div>
      <draggable
        v-model="includedColumns"
        tag="ul"
        handle=".drag-handle"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        @change="updateTableColumnOrder"
      >
        <transition-group
          type="transition"
          :name="!drag ? 'flip-list' : null"
        >
          <!-- Selected list -->
          <div
            v-for="kpi in includedColumns"
            :key="kpi.key"
            class="flex items-center gap-1 justify-between py-1 px-0.5"
          >
            <div
              class="flex gap-1 truncate"
            >
              <DragListIcon
                class="text-icon-disabled flex-shrink-0 drag-handle cursor-pointer"
              />
              <BaseText
                class="text-text-muted capitalize flex-shrink truncate"
                type="label"
                size="sm"
              >
                {{ getMetricLookup[kpi.key]?.name || kpi }}
              </BaseText>
            </div>
            <TagRemoveIcon
              class="cursor-pointer flex-shrink-0"
              @click="removeKPI(kpi, false)"
            />
          </div>
        </transition-group>
      </draggable>
    </div>
    <div
      v-else
      class="w-full h-full flex flex-col items-center justify-center gap-1 text-center"
    >
      <BaseText
        class="text-text-normal"
        type="label"
        size="sm"
      >
        No Selected KPI's
      </BaseText>
      <BaseText
        class="text-text-subdued w-64"
        size="sm"
      >
        Add KPI's to your report by selecting them from the list on the left.
      </BaseText>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
// Icons
import PinIcon from '../../globals/Icons/SpyderV2Icons/PinIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import DragListIcon from '../../globals/Icons/DragListIcon.vue'

export default {
  name: 'SelectedKPISidebar',
  components: {
    TagRemoveIcon,
    DragListIcon,
    PinIcon,
    draggable
  },
  props: {
    hideTitle: {
      type: Boolean,
      default: () => false
    },
    noPadding: {
      type: Boolean,
      default: () => false
    },
    noBorder: {
      type: Boolean,
      default: () => false
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const dragOptions = {
      animation: 150,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost'
    }

    return {

      dragOptions,
      drag: false,
      includedColumns: [],
      pinnedColumns: []
    }
  },
  watch: {
    columns (val) {
      const groupedCols = this.getGroupedColumns(val)
      this.includedColumns = groupedCols.unpinned
      this.pinnedColumns = groupedCols.pinned
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup'])
  },
  mounted () {
    const groupedCols = this.getGroupedColumns(this.columns)
    this.includedColumns = groupedCols.unpinned
    this.pinnedColumns = groupedCols.pinned
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    getGroupedColumns (columns) {
      return columns.reduce((acc, column) => {
        if (column.is_pinned) {
          acc.pinned.push(column)
        } else {
          acc.unpinned.push(column)
        }
        return acc
      }, { pinned: [], unpinned: [] })
    },
    getKeyColumns (arr) {
      return arr.map((val) => val.key)
    },
    updateTableColumnOrder () {
      const pinnedCols = this.getKeyColumns(this.pinnedColumns)
      const changedUnpinCols = this.getKeyColumns(this.includedColumns)
      this.updateLocalPresetField({
        key: 'table_columns',
        value: [...pinnedCols, ...changedUnpinCols]
      })
      this.$emit('change')
    },
    removeKPI (kpi, isPinned) {
      const kpiKey = kpi.key
      if (!isPinned) {
        this.includedColumns = this.includedColumns.filter(kpi => kpi.key !== kpiKey)
      } else {
        this.pinnedColumns = this.pinnedColumns.filter((kpi) => kpi.key !== kpiKey)
      }
      this.updateTableColumnOrder()
    }
  }

}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;

}

.ghost {
  opacity: 0.5;
  background-color: #F6F8FA;
}
</style>
