<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.20817 6.24999C5.78347 6.24999 6.24984 5.78362 6.24984 5.20832C6.24984 4.63303 5.78347 4.16666 5.20817 4.16666C4.63287 4.16666 4.1665 4.63303 4.1665 5.20832C4.1665 5.78362 4.63287 6.24999 5.20817 6.24999Z"
      fill="currentColor"
    />
    <path
      d="M10.8332 10.784V14.2165C10.8332 14.7221 11.4023 15.0185 11.8166 14.7285L14.2684 13.0122C14.6238 12.7634 14.6238 12.237 14.2684 11.9882L11.8166 10.2719C11.4023 9.98197 10.8332 10.2783 10.8332 10.784Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.6665 3.95832C1.6665 2.69267 2.69252 1.66666 3.95817 1.66666H11.0415C12.3072 1.66666 13.3332 2.69267 13.3332 3.95832V6.66666H16.0415C17.3072 6.66666 18.3332 7.69267 18.3332 8.95832V16.0417C18.3332 17.3073 17.3072 18.3333 16.0415 18.3333H8.95817C7.69252 18.3333 6.6665 17.3073 6.6665 16.0417V13.3333H3.95817C2.69252 13.3333 1.6665 12.3073 1.6665 11.0417V3.95832ZM12.0832 3.95832V6.66666H8.95817C7.77869 6.66666 6.80733 7.55771 6.68052 8.70339L6.26941 8.42931C5.49964 7.91613 4.4968 7.91613 3.72703 8.42931L2.9165 8.96966V3.95832C2.9165 3.38303 3.38287 2.91666 3.95817 2.91666H11.0415C11.6168 2.91666 12.0832 3.38303 12.0832 3.95832ZM5.57603 9.46937L6.6665 10.1964V12.0833H3.95817C3.38287 12.0833 2.9165 11.617 2.9165 11.0417V10.472L4.42041 9.46937C4.7703 9.23611 5.22613 9.23611 5.57603 9.46937ZM7.9165 16.0417V8.95832C7.9165 8.38303 8.38287 7.91666 8.95817 7.91666H16.0415C16.6168 7.91666 17.0832 8.38303 17.0832 8.95832V16.0417C17.0832 16.617 16.6168 17.0833 16.0415 17.0833H8.95817C8.38287 17.0833 7.9165 16.617 7.9165 16.0417Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'MediaIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
