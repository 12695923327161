<template>
<BaseDrawer
  no-padding
  height-auto-only
  no-bottom-rounding
  noToolbar
  force-no-scroll
  class="scrollbar-hide"
  @close="$emit('close')"
>
<template #content>
  <div class="w-full px-1 pb-2 pt-1 rounded-3xl bg-neutral-25 overflow-hidden" style="height: calc(100vh - 80px); min-height: auto">
    <div
      v-if="loading || groupData === null"
      class="flex flex-col gap-1 items-center justify-center w-full h-full"
    >
      <BaseLoadingSpinnerCircle
        :width="24"
        :height="24"
        class="text-text-muted"
      />
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted"
      >
        Crunching ad numbers...
      </BaseText>
      <BaseText
        size="sm"
        class="text-text-normal"
      >
        This might take a few seconds.
      </BaseText>
    </div>
    <div v-else class="content-area flex flex-col fade-in">
      <!-- Drawer Header -->
      <div class="w-full flex items-center gap-1.5 p-4 min-w-0 border-b border-border-normal">
        <GroupDetailsIcon class="text-icon-normal flex-shrink-0" />
        <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap capitalize">
          {{ localReport?.group_by?.replace(/_/g, ' ') }} Breakdown
        </BaseText>
        <BaseText type="label" size="sm" class="ml-1 text-text-normal min-w-0 flex-shrink truncate">
          {{ groupData.group_by }}
        </BaseText>
      </div>
      <!-- Drawer Body -->
      <div class="drawer-body w-full flex-grow p-4 min-h-0 overflow-y-scroll scrollbar-hide">
        <!-- Header Breakdown Stats -->
        <div class="w-full grid grid-cols-3 gap-x-4">
          <!-- Total Spend -->
          <div class="header-stat border border-border-normal">
            <div class="flex items-center gap-1">
              <BaseText type="label" size="sm" class="text-text-normal">
                Total Spend
              </BaseText>
            </div>
            <BaseText type="display" size="2xs" class="text-text-muted">
              ${{ 
                getIsPrivateMode 
                  ? '•••••' 
                  : groupData.spend.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) 
              }}
            </BaseText>
            <div v-if="hasDateRange" class="flex items-center gap-1">
              <PeriodDiffIcon v-if="spendDiffData.text" class="transform" :class="spendDiffData.colorClass"
              :style="{ transform: spendDiffData.isPositive ? 'scaleY(1)' : 'scaleY(-1)' }" />
              <PeriodDiffNoChangeIcon v-else class="text-text-subdued" />
              <BaseText v-if="spendDiffData.text" type="label" size="sm" :class="spendDiffData.colorClass">
                {{ spendDiffData.text }}
              </BaseText>
              <BaseText type="body" size="sm" class="text-text-normal ml-0.5">
                {{ spendDiffData.qualifier }}
              </BaseText>
            </div>
          </div>
          <!-- Goal Metric -->
          <div class="header-stat border border-border-normal">
            <div class="flex items-center gap-1">
              <BaseText type="label" size="sm" class="text-text-normal">
                Goal Metric Avg. {{ goalMetricAvgDiffData?.name || '--' }}
              </BaseText>
              <div class="relative">
                <InfoIcon class="text-icon-normal" :width="20" :height="20"
                @mouseenter.native="groupBreakdownInfoHovered = true" @mouseleave.native="groupBreakdownInfoHovered = false" />
                <transition>
                  <div v-if="groupBreakdownInfoHovered" class="goal-metric-info-tooltip px-2 py-1 bg-neutral-800 rounded-md shadow-md cursor-default">
                    <BaseText type="body" size="sm" class="text-white" style="width: 170px">
                      Change your goal metric in account settings
                    </BaseText>
                  </div>
                </transition>
              </div>
            </div>
            <BaseText type="display" size="2xs" class="text-text-muted">
              {{ 
                getIsPrivateMode 
                  ? '•••••' 
                  : goalMetricAvgDiffData?.value || '--' 
              }}
            </BaseText>
            <div v-if="hasDateRange" class="flex items-center gap-1">
              <PeriodDiffIcon v-if="goalMetricAvgDiffData?.diffText" class="transform" :class="goalMetricAvgDiffData?.diffColorClass"
              :style="{ transform: goalMetricAvgDiffData?.isPositive ? 'scaleY(1)' : 'scaleY(-1)' }" />
              <PeriodDiffNoChangeIcon v-else class="text-text-subdued" />
              <BaseText v-if="goalMetricAvgDiffData?.diffText" type="label" size="sm" :class="goalMetricAvgDiffData?.diffColorClass">
                {{ goalMetricAvgDiffData?.diffText || '--' }}
              </BaseText>
              <BaseText type="body" size="sm" class="text-text-normal ml-0.5">
                {{ goalMetricAvgDiffData?.diffQualifier || '--' }}
              </BaseText>
            </div>
          </div>
          <!-- Number of Ads -->
          <div class="header-stat border border-border-normal">
            <div class="flex items-center gap-1">
              <BaseText type="label" size="sm" class="text-text-normal">
                Number of Ads
              </BaseText>
            </div>
            <BaseText type="display" size="2xs" class="text-text-muted">
              {{ groupData.active_ad_count }}/{{ groupData.ads.length }} Active
            </BaseText>
            <div v-if="hasDateRange" class="flex items-center gap-1">
              <PeriodDiffIcon v-if="adCountDiffData.text" class="transform" :class="adCountDiffData.colorClass"
              :style="{ transform: adCountDiffData.isPositive ? 'scaleY(1)' : 'scaleY(-1)' }" />
              <PeriodDiffNoChangeIcon v-else class="text-text-subdued" />
              <BaseText v-if="adCountDiffData.text" type="label" size="sm" :class="adCountDiffData.colorClass">
                {{ adCountDiffData.text }}
              </BaseText>
              <BaseText type="body" size="sm" class="text-text-normal ml-0.5">
                {{ adCountDiffData.qualifier }}
              </BaseText>
            </div>
          </div>
        </div>
        <!-- Graphs -->
        <LensReportGraph 
          class="mt-4"
          is-ad-level
          has-mounted
          :graph-type.sync="graphType"
          :data-items="graphDataItems"
          :data-summary="graphDataSummary"
          :selected-kpis="selectedColumns[graphType]"
          :grouped-by="localReport?.group_by"
        />
        <!-- Lens Metric Table -->
        <LensMetricTable 
          class="mt-4"
          is-ad-level
          :loading="isSorting"
          
          :data="tableData"
          :selected-columns="selectedColumns[graphType]"
          :selected-rows="selectedRows"
          :current-sort="localReport?.sorted_column"
          :table-pagination="pagination"

          :table-columns="localReport?.table_columns"
          :table-config="localReport?.table_config"

          :grouped-by="localReport?.group_by"
          :graph-info="graphInfo"
          :max-row-count="totalAllowedRows"

          @update:selectedColumns="updateSelectedColumns"
          @update:selectedRows="updateSelectedRows"
          @reportChanged="handleLocalReportChange"
          @paginationChanged="handlePaginationChange"
        />
      </div>
    </div>
  </div>
</template>
</BaseDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import formatLabelString from '../../../utils/lens/formatGraphLabelString'

// Components
import LensMetricTable from '../table/LensMetricTable.vue'
import LensReportGraph from '../graphs/LensReportGraph.vue'

// Icons
import GroupDetailsIcon from '../../globals/Icons/LensIcons/GroupDetailsStaticIcon.vue'
import InfoIcon from '../../globals/Icons/InfoSimpleIcon.vue'
import PeriodDiffIcon from '../../globals/Icons/LensIcons/PeriodDiffIcon.vue'
import PeriodDiffNoChangeIcon from '../../globals/Icons/LensIcons/PeriodDiffNoChangeIcon.vue'

// key: name label
const METADATA_COLS = {
  campaign_name: "Campaign Name",
  adset_name: "Ad Set Name",
  ad_title: "Headline",
  destination_url: "Landing Page",
  ad_copy: "Ad Copy"
}

export default {
  name: 'GroupBreakdownDrawer',
  components: {
    LensMetricTable,
    LensReportGraph,
    GroupDetailsIcon,
    InfoIcon,
    PeriodDiffIcon,
    PeriodDiffNoChangeIcon
  },
  props: {
    groupData: {
      type: [Object, null],
      default: null
    },
    currentLens: {
      type: Object,
      required: true
    },
    currentReport: {
      type: Object,
      required: true
    },
    selectedDateRange: {
      type: Object,
      required: true
    },
    reportSummaryStats: {
      type: Object,
      required: true
    },
    graphConsts: {
      type: Object,
      required: true
    },
    initGraphType: {
      type: String,
      default: 'bar'
    },
    initSelectedColumns: {
      type: Object,
      default: () => ({ bar: [], line: [], grid: [] })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localReport: null,

      advertisements: [],
      graphType: this.initGraphType,
      selectedColumns: {
        bar: [],
        line: [],
        grid: []
      },
      selectedRows: [],

      pagination: {
        currentPage: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0
      },

      isSorting: false,
      groupBreakdownInfoHovered: false
    }
  },
  watch: {
    graphType (type) {
      console.log('%c WATCHING DRAWER GRAPH TYPE', 'color: pink; font-size: 18px;')
      if (this.advertisements.length) {
        const rowCount = this.localReport?.selected_graph_rows?.[type]
        this.selectedRows = this.advertisements.slice(0, rowCount)
      }
      this.localReport.graph_type = type
    },
    loading (newVal) {
      if (!newVal) {
        this.initBreakdown()
      }
    }
  },
  created () {
    if (!this.loading) {
      this.initBreakdown()
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('MiscModule', ['getIsPrivateMode']),
    tableData () {
      // We need to apply pagination slicing to the group data ads
      const startIndex = (this.pagination.currentPage - 1) * this.pagination.limit
      const endIndex = startIndex + this.pagination.limit
      return {
        ...this.groupData,
        ads: this.advertisements.slice(startIndex, endIndex)
      }
    },
    graphDataItems () {
      const totalItems = this.pagination.totalItems
      return totalItems > 0 ? this.selectedRows : []
    },
    graphDataSummary () {
      return {
        ...this.reportSummaryStats,
        startDate: this.selectedDateRange.start,
        endDate: this.selectedDateRange.end
      }
    },
    graphInfo () {
      return {
        selectedGraph: this.graphType,
        data: {
          bar: {
            selected: this.selectedColumns?.bar?.length || 0,
            total: this.graphConsts.maxCols.bar
          },
          line: {
            selected: this.selectedColumns?.line?.length || 0,
            total: this.graphConsts.maxCols.line
          },
          grid: {
            selected: this.selectedColumns?.grid?.length || 0,
            total: this.graphConsts.maxCols.grid
          }
        }
      }
    },
    totalAllowedRows () {
      return this.graphConsts.maxRows?.[this.graphType] ?? 8
    },
    hasDateRange () {
      return this.selectedDateRange.start && this.selectedDateRange.end
    },
    spendDiffData () {
      const currentSpend = this.groupData.spend
      const previousSpend = this.groupData.previous_period.spend
      if (previousSpend === 0 && currentSpend > 0) {
        return { text: null, qualifier: 'No spend in previous period'}
      } else if (previousSpend === 0 && currentSpend === 0) {
        return { text: null, qualifier: 'No spend in this period or previous'}
      } else if (previousSpend > 0 && currentSpend === 0) {
        return { text: null, qualifier: 'No spend in this period'}
      }
      const percentChange = ((currentSpend - previousSpend) / previousSpend) * 100
      const colorClass = percentChange > 0 ? 'text-secondary-green-200' : 'text-secondary-red-200'
      return { 
        text: `${percentChange.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}%`,
        qualifier: 'since previous period',
        colorClass,
        isPositive: percentChange > 0
      }
    },
    goalMetricAvgDiffData () {
      const currentValue = this.groupData.weighted_averages?.[this.currentLens.goal_metric]
      const previousValue = this.groupData.previous_period?.weighted_averages?.goal_metric
      if (!currentValue) return null
      const metricName = this.getMetricLookup?.[this.currentLens.goal_metric]?.name || '--'
      const metricValue = formatLabelString(currentValue, this.getMetricLookup?.[this.currentLens.goal_metric]?.type)

      if (previousValue === 0 && currentValue > 0) {
        return { diffText: null, diffQualifier: 'No previous period data' }
      } else if (previousValue === 0 && currentValue === 0) {
        return { diffText: null, diffQualifier: 'No data in this period or previous' }
      } else if (previousValue > 0 && currentValue === 0) {
        return { diffText: null, diffQualifier: 'No data in this period' }
      }
      const percentChange = ((currentValue - previousValue) / previousValue) * 100
      const diffColorClass = percentChange > 0 ? 'text-secondary-green-200' : 'text-secondary-red-200'

      return {
        name: metricName,
        value: metricValue,
        diffText: `${percentChange.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}%`,
        diffQualifier: 'since previous period',
        diffColorClass,
        isPositive: percentChange > 0
      }
    },
    adCountDiffData () {
      const currentAdCount = this.groupData.active_ad_count
      const previousAdCount = this.groupData.previous_period.active_ad_count
      if (previousAdCount === currentAdCount) {
        return { text: null, qualifier: 'No change since previous period'}
      } else {
        const countDiff = currentAdCount - previousAdCount
        return {
          text: `${countDiff > 0 ? '+' : ''}${countDiff}`,
          qualifier: 'since previous period',
          colorClass: countDiff > 0 ? 'text-secondary-green-200' : 'text-secondary-red-200',
          isPositive: countDiff > 0
        }
      }
    }
  },
  methods: {
    async initBreakdown () {
      // Clone the report to avoid mutating the original
      // Changes to sort, pins, etc will be localized to this drawer
      const parentReport = _.cloneDeep(this.currentReport)
      // Set the initial number of selected rows for each graph type
      parentReport.selected_graph_rows = {
        bar: Math.min(6, this.groupData.ads.length),
        line: Math.min(4, this.groupData.ads.length),
        grid: Math.min(this.computeInitGridCardCount(), this.groupData.ads.length)
      }
      // Expand the report's table columns to put spend first (if present) and include ad metadata
      const spendColumn = parentReport.table_columns?.find(col => col.key === 'spend')
      const metadataColumns = this.computeMetadataColumnDefs(Object.keys(METADATA_COLS))
      parentReport.table_columns = [
        ...(spendColumn ? [spendColumn] : []),
        ...metadataColumns,
        ...(parentReport.table_columns?.filter(col => col.key !== 'spend') || [])
      ]
      this.localReport = parentReport

      // Update pagination values based on group data
      const totalItems = this.groupData.ads.length
      const totalPages = Math.ceil(totalItems / this.pagination.limit)
      this.pagination = {
        ...this.pagination,
        totalItems,
        totalPages
      }

      // Initialize selected columns/graph type based on what was currently selected in the report
      this.selectedColumns = { ...this.initSelectedColumns }

      // By default, sort the ads based on the report's sorted column
      if (this.localReport?.sorted_column) {
        await this.sortAdvertisements(this.localReport.sorted_column.by, this.localReport.sorted_column.order)
      } else {
        this.advertisements = this.groupData.ads
      }

      // Automatically select the first 4 rows for each type
      // TODO: For grid, maybe can compute the amount of cards that can fit in one row...
      this.selectedRows = this.advertisements.slice(0, parentReport.selected_graph_rows[this.initGraphType])
    },
    handleLocalReportChange (change) {
      const { type, value } = change
      this.localReport = {
        ...this.localReport,
        [type]: value
      }
      if (type === 'sorted_column') {
        // Apply sorting to the ads
        this.sortAdvertisements(value.by, value.order)
      }
    },
    handlePaginationChange (change) {
      const { type, value } = change
      // If no type specified, spread into object parents
      this.pagination = type 
        ? { ...this.pagination, [type]: value } 
        : { ...this.pagination, ...value }
      this.pagination.totalPages = Math.floor(this.pagination.totalItems / this.pagination.limit) || 1
    },
    updateSelectedColumns (columns) {
      this.selectedColumns[this.graphType] = columns
      this.localReport.selected_columns[this.graphType] = [...columns]
    },
    updateSelectedRows (rows) {
      this.selectedRows = rows
      this.localReport.selected_graph_rows[this.graphType] = rows.length
    },
    async sortAdvertisements (key, order) {
      let sortComputing = true
      // Only show loading state if sort takes longer than 100ms
      setTimeout(() => { if (sortComputing) this.isSorting = true }, 100)
      // Execute the sort asynchronously
      try {
        const unsortedAds = [...this.groupData.ads]
        const sortedAds = await new Promise(resolve => {
          // Use setTimeout to make this non-blocking
          setTimeout(() => {
            const result = unsortedAds.sort((a, b) => {
              if (order === 'asc') {
                return a[key] - b[key]
              } else {
                return b[key] - a[key]
              }
            })
            resolve(result)
          }, 0)
        })
        this.advertisements = sortedAds
        // Reset the selected rows to the first x of the new sorted list
        const rowCount = this.localReport?.selected_graph_rows?.[this.graphType]
        this.selectedRows = sortedAds.slice(0, rowCount)
      } catch (error) {
        console.error('Error sorting advertisements', error)
        this.$showAlert({
          type: 'error',
          message: 'Error sorting advertisements'
        })
      } finally {
        sortComputing = false
        this.isSorting = false
      }
    },
    computeMetadataColumnDefs (keys) {
      // We need to compute the number of unique values for each metadata column
      const uniqueValues = new Map()
      keys.forEach(key => { uniqueValues.set(key, new Set()) })
      for (const ad of this.groupData.ads) {
        keys.forEach(key => {
          if (ad[key]?.length) uniqueValues.get(key).add(ad[key])
        })
      }
      return keys.map(key => ({
        key,
        name: METADATA_COLS[key],
        is_pinned: false,
        is_metadata: true,
        unique_values: uniqueValues.get(key).size
      }))
    },
    computeInitGridCardCount () {
      const maxRows = this.graphConsts.maxRows.grid
      const minCardWidth = 275
      const gapWidth = 4 
      const availableWidth = window.innerWidth - 72
      
      // Formula: totalWidth = (n * cardWidth) + ((n-1) * gapWidth)
      // Solving for n: n = (totalWidth + gapWidth) / (cardWidth + gapWidth)
      // Floor n to get the whole number representing the max cards that can fit
      const cardCount = Math.floor((availableWidth + gapWidth) / (minCardWidth + gapWidth))
      return Math.min(cardCount, maxRows)
    }
  }
}
</script>

<style scoped>
.content-area {
  border-radius: 22px;
  background-color: white;
  height: 100%;
  width: 100%;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.06), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.06);
}
.header-stat {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.drawer-body {
  overscroll-behavior-y: none;
  scroll-behavior: auto;
  -webkit-overflow-scrolling: none;
}
.goal-metric-info-tooltip {
  position: absolute;
  left: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>