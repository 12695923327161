<template>
  <div
    class="flex items-center gap-1 flex-wrap"
    style="min-height: 1.5rem"
  >
    <BaseText
      v-for="(part, index) in formulaParts"
      :key="index"
      type="heading"
      size="overline"
      class="rounded-md text-center"
      :class="`formula-${getNodeType(part.type, part.value)}`"
    >
      <div
        v-if="part.type === 'operation'"
        class="p-2"
      >
        <component
          :is="operatorMapping[part.value]"
          :width="12"
          :height="12"
        />
      </div>
      <span
        v-else
        class="py-1 px-2"
      >
        {{ getNodeType(part.type, part.value) === 'integer' ? Number(part.value).toLocaleString() : part.value }}
      </span>
    </BaseText>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Icons
import PlusIcon from '../globals/Icons/MathOperators/PlusIcon.vue'
import MinusIcon from '../globals/Icons/MathOperators/MinusIcon.vue'
import MultiplyIcon from '../globals/Icons/MathOperators/MultiplyIcon.vue'
import DivideIcon from '../globals/Icons/MathOperators/DivideIcon.vue'
export default {
  name: 'MetricFormulaRenderer',
  components: {
    PlusIcon,
    MinusIcon,
    MultiplyIcon,
    DivideIcon
  },
  props: {
    ast: {
      type: Object,
      required: true
    }
  },
  data () {
    const operatorMapping = {
      '/': DivideIcon,
      '*': MultiplyIcon,
      '-': MinusIcon,
      '+': PlusIcon
    }

    return {
      operatorMapping
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    formulaParts () {
      const parts = []
      this.traverseAST(this.ast, parts)
      return parts
    }
  },
  methods: {
    getNodeType (type, val) {
      return !isNaN(val) ? 'integer' : type
    },
    traverseAST (node, parts) {
      if (node.type === 'binOp') {
        this.traverseAST(node.left, parts)
        parts.push({ type: 'operation', value: node.operation })
        this.traverseAST(node.right, parts)
      } else if (node.type === 'literal') {
        // Relative keys are denoted as [relative_space].[metric_key]
        const metricData = `${node.value}`.split('.')
        const key = metricData[metricData.length - 1]
        const formatKey = this.getMetricLookup?.[key]
        parts.push({ type: 'literal', value: formatKey?.name ?? key, isRelative: metricData.length > 1 })
      }
    }
  }
}
</script>

<style scoped>

.formula-literal{
    color: rgba(60, 148, 249, 1);
    background-color: rgba(60, 148, 249, 0.06);
    width: auto;
    padding: 2px 0;
}

.formula-operation{
    color: rgba(137,113,234,1);
    background-color: rgba(137,113,234,0.06);
    height: auto;
}

.formula-integer{
  color: rgba(39, 176, 139, 1);
  background-color: rgba(39, 176, 139, 0.06);
  padding: 2px 0;
}
</style>
