<template>
  <div class="query-builder-container flex flex-col">
    <!-- Query Conditions -->
    <transition-group name="condition">
      <div v-for="(condition, index) in filterConditions" :key="condition.renderKey" style="height: 58px;">
        <QueryCondition 
          :filter-condition="condition"
          :is-first-condition="index === 0"
          :filter-option-type="filterOptionType"
          @selectProperty="changeConditionProperty(index, $event)"
          @selectOperation="changeConditionOperation(index, $event)"
          @updateConditionValue="updateConditionValue(index, $event)"
          @removeCondition="removeCondition(index)"
        />
      </div>
    </transition-group>
    <!-- Add Condition Button -->
    <div class="w-full flex justify-center items-center p-2 border-b border-border-normal">
      <button class="flex items-center gap-1.5 pl-1.5 py-1.5 pr-2.5 rounded-md transition-colors hover:bg-neutral-25"
      @click="addCondition">
        <PlusIcon class="text-icon-normal" />
        <BaseText type="label" size="sm" class="text-text-muted">
          Add 'OR' Condition
        </BaseText>
      </button>
    </div>
    <!-- Apply/Cancel Buttons -->
    <div class="w-full flex justify-between items-center p-2">
      <button class="px-2.5 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
      @click="$emit('close')">
        <BaseText type="label" size="sm" class="text-text-muted">
          Cancel
        </BaseText>
      </button>
      <button class="apply-button px-3 py-1.5 rounded-md" :class="{ disabled: !canCreateFilter }"
      :disabled="!canCreateFilter" @click="createFilter">
        <BaseText type="label" size="sm">
          Apply
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import smoothReflow from 'vue-smooth-reflow'
import QueryCondition from './QueryCondition.vue'

import PlusIcon from '../../globals/Icons/PlusIcon.vue'

export default {
  name: 'LensFilterQueryBuilder',
  mixins: [smoothReflow],
  components: {
    QueryCondition,
    PlusIcon
  },
  props: {
    initFilterOption: {
      type: Object,
      default: () => {}
    },
    initFilterConditions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filterConditions: [],
      filterOptionType: null
    }
  },
  created () {
    if (this.initFilterConditions.length) {
      this.filterOptionType = this.initFilterConditions[0].property.optionType
      this.filterConditions = _.cloneDeep(this.initFilterConditions)
    } else {
      this.filterOptionType = this.initFilterOption.optionType
      const initialValue = this.initFilterOption.input?.options?.[0]?.value || null // If it's a dropdown type
      const initialCondition = {
        property: this.initFilterOption,
        operation: this.initFilterOption?.validOperations?.[0] || null,
        value: initialValue,
        renderKey: nanoid() // For rendering purposes
      }
      this.filterConditions.push(initialCondition)
    }
  },
  computed: {
    canCreateFilter () {
      // If we're starting with init filter conditions, make sure at least one condition is different
      const changesMade = this.initFilterConditions.length !== this.filterConditions.length
        || this.filterConditions.some((condition, index) =>
          condition.property.key !== this.initFilterConditions?.[index]?.property?.key
          || condition.operation.key !== this.initFilterConditions?.[index]?.operation?.key
          || condition.value !== this.initFilterConditions?.[index]?.value
        )

      // Check that all the properties of each condition are not null or empty
      return this.filterConditions.every(condition => condition.property && condition.operation && (condition.value || condition.value === 0))
        && (
          !this.initFilterConditions.length
          || changesMade
        )
    }
  },
  methods: {
    createFilter () {
      this.$emit('createFilter', this.filterConditions)
      this.$emit('close')
    },
    addCondition () {
      const firstCondition = this.filterConditions[0]
      const initialValue = firstCondition.property?.input?.options?.[0]?.value || null // If it's a dropdown type
      const newCondition = {
        property: firstCondition.property,
        operation: firstCondition.property?.validOperations?.[0] || null,
        value: initialValue,
        renderKey: nanoid() // For rendering purposes
      }
      this.filterConditions.push(newCondition)
    },
    removeCondition (index) {
      if (this.filterConditions.length > 1) {
        this.filterConditions.splice(index, 1)
      } else {
        this.$emit('close')
      }
    },
    changeConditionProperty (conditionIndex, newProperty) {
      const oldCondition = this.filterConditions[conditionIndex]
      const newCondition = {
        property: newProperty,
        operation: newProperty?.validOperations?.[0] || null,
        value: null,
        renderKey: oldCondition.renderKey
      }
      this.filterConditions.splice(conditionIndex, 1, newCondition)
    },
    changeConditionOperation (conditionIndex, newOperation) {
      this.filterConditions[conditionIndex].operation = newOperation
    },
    updateConditionValue (conditionIndex, value) {
      this.filterConditions[conditionIndex].value = value
    }
  }
}
</script>

<style scoped>
.query-builder-container {
  min-width: 350px;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

@property --apply-btn-opacity {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --apply-btn-color {
  syntax: '<color>';
  initial-value: #121212;
  inherits: false;
}
.apply-button {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--apply-btn-opacity)) 0%, rgba(255, 255, 255, var(--apply-btn-opacity)) 100%), var(--apply-btn-color);
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #000;
  color: white;
  transition: box-shadow 100ms ease-in-out, 
    color 100ms ease-in-out, 
    --apply-btn-opacity 100ms ease-in-out,
    --apply-btn-color 100ms ease-in-out;
}
.apply-button.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --apply-btn-opacity: 0;
  --apply-btn-color: #F6F8FA;
}

/* ========= Vue <transition> classes ========= */
.condition-enter-active {
  animation: conditionEnter 250ms ease-in-out forwards;
}
.condition-leave-active {
  animation: conditionLeave 250ms ease-in-out forwards;
}
.condition-move {
  transition: transform 1s;
}
@keyframes conditionEnter {
  0% { opacity: 0; height: 0px; }
  50% { opacity: 0; height: 58px; }
  100% { opacity: 1; height: 58px; }
}
@keyframes conditionLeave {
  0% { opacity: 1; height: 58px; }
  50% { opacity: 0; height: 58px; }
  100% { opacity: 0; height: 0px; }
}
</style>
