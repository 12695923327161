<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M3.33331 6.66683V5.00016C3.33331 4.07969 4.0795 3.3335 4.99998 3.3335H6.66665M3.33331 13.3335V15.0002C3.33331 15.9207 4.0795 16.6668 4.99998 16.6668H6.66665M13.3333 3.3335H15C15.9205 3.3335 16.6666 4.07969 16.6666 5.00016V6.66683M16.6666 13.3335V15.0002C16.6666 15.9207 15.9205 16.6668 15 16.6668H13.3333M12.5 10.0002C12.5 11.3809 11.3807 12.5002 9.99998 12.5002C8.61923 12.5002 7.49998 11.3809 7.49998 10.0002C7.49998 8.61941 8.61923 7.50016 9.99998 7.50016C11.3807 7.50016 12.5 8.61941 12.5 10.0002Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'StaticReportIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
