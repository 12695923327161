import { render, staticRenderFns } from "./FormulaIcon.vue?vue&type=template&id=7b066736&scoped=true"
import script from "./FormulaIcon.vue?vue&type=script&lang=js"
export * from "./FormulaIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b066736",
  null
  
)

export default component.exports