
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M11.5007 2.5C11.5007 2.5 11.634 3.28001 11.5007 4C11.3571 4.77545 10.7507 5.5 10.7507 5.5M12.6257 7.375L13.3757 6.625M14.7257 4.5625L15.0632 3.625M16.0007 7L16.7507 6.625M14.5007 9.25C14.5007 9.25 15.0678 9.274 15.6257 9.4525C16.1286 9.61345 16.7507 10 16.7507 10M5.33576 16.6105L12.3726 13.8385C13.3778 13.4426 13.6474 12.1461 12.8835 11.3823L8.61895 7.11766C7.85513 6.35382 6.55868 6.62344 6.16271 7.62848L3.39041 14.665C2.90973 15.8851 4.11569 17.0911 5.33576 16.6105Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SuccessPartyIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>
