<template>
  <div class="w-full flex flex-col">
    <!-- Search metrics input -->

    <!-- Search Icon -->
    <!-- TODO @Sam: Add hover/focus style -->
    <div class="flex items-center w-full search-container px-2 text-sm rounded-md search-input-container">
      <DynamicSearchIcon
        class="text-white text-opacity-25 group-hover:text-opacity-100"
      />
      <!-- Search Input -->
      <input
        v-model="textSearch"
        type="text"
        class="w-full search-input outline-none border-none text-black h-8"
        placeholder="Search metrics"
        @input="debounceSearch"
      >
      <BaseLoadingSpinnerCircle
        v-if="loadingSearch"
        class="text-icon-normal"
      />
    </div>

    <!-- Scores -->
    <div v-if="showFPMetrics">
      <BaseText
        type="heading"
        size="sm"
        class="my-5"
      >
        Foreplay Metrics
      </BaseText>

      <!-- Calculated -->
      <div class="flex flex-col gap-2.5">
        <BaseText
          id="calculated-scores"
          ref="calculated-scores"
          type="heading"
          size="overline"
          class="text-text-subdued uppercase metric-section"
        >
          Calculated Scores
        </BaseText>
        <div class="grid grid-cols-4 gap-2">
          <div
            v-for="(score, index) in calculatedScores"
            :key="`score-${index}`"
            class="flex flex-col gap-1.5 col-span-4 lg:col-span-2 relative p-3 rounded-xl border border-border-normal cursor-pointer"
            @click="handleMetricAdd(score.key)"
          >
            <input
              type="checkbox"
              :checked="presetCalculatedScores.includes(score.key)"
              class="h-4 w-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent absolute top-3 right-3 transition-colors"
            >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              {{ score.title }}
            </BaseText>
            <BaseText>
              <BaseText
                type="body"
                size="sm"
                class="text-text-normal"
              >
                {{ score.desc }}
              </BaseText>
            </basetext>
          </div>
          <!-- Learn more -->
          <!-- TODO @Sam: update with knowledge base -->
          <a
            href="https://foreplay.co"
            target="_blank"
            class="learn-btn cursor-pointer flex flex-col gap-1.5 col-span-4 lg:col-span-2 relative p-5 rounded-xl border border-border-normal overflow-hidden"
          >
            <!-- Lens icon -->
            <FPLensMetricsIcon
              class="absolute -bottom-12 right-0"
              :width="115"
              :height="125"
            />
            <BaseText
              type="body"
              size="sm"
              class="text-white opacity-60"
            >
              Learn more about
            </BaseText>
            <div class="flex gap-1">
              <MetricsIcon
                class="text-white"
              />
              <BaseText
                type="label"
                size="sm"
                class="text-white"
              >
                Foreplay Metrics
              </BaseText>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import _ from 'lodash'
// Icons
import DynamicSearchIcon from '../../../globals/Icons/SearchIcons/DynamicSearchIcon.vue'
import MetricsIcon from '../../../globals/Icons/LensIcons/MetricsIcon.vue'
import FPLensMetricsIcon from '../../../globals/Icons/LensIcons/FPLensMetricsIcon.vue'
import { mapGetters } from 'vuex/dist/vuex.common.js'
export default {
  name: 'ForeplayMetricsSettings',
  components: {
    DynamicSearchIcon,
    MetricsIcon,
    FPLensMetricsIcon
  },
  props: {
    presetCalculatedScores: {
      type: Array,
      default: () => []
    }
  },
  data () {
    // TODO @Sam: update desc
    const calculatedScores = [
      {
        title: 'Hook Score',
        key: 'hook_score',
        desc: 'The ability of this video to hook a viewer into watching it.'
      },
      {
        title: 'Watch Score',
        key: 'watch_score',
        desc: 'The ability of this video to keep a viewer watching it.'
      },
      {
        title: 'Click Score',
        key: 'click_score',
        desc: 'The ability of this video to keep a viewer watching it.'
      },
      {
        title: 'Convert Score',
        key: 'convert_score',
        desc: 'The ability of this video to keep a viewer watching it.'
      },
      {
        title: 'Viral Engagement Score',
        key: 'viral_engagement_score',
        desc: 'The ability of this video to keep a viewer watching it.'
      }
    ]
    return {
      searchBarHovered: false,
      textSearch: '',
      calculatedScores,
      loadingSearch: false,
      showFPMetrics: true
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getFuse'])
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    ...mapActions('MetricsModule', ['updateTextSearch', 'setFuzzyResults']),
    handleMetricAdd (key) {
      let newCalculatedScores = [...this.presetCalculatedScores]
      if (newCalculatedScores.includes(key)) {
        newCalculatedScores = newCalculatedScores.filter(score => score !== key)
      } else {
        newCalculatedScores.push(key)
      }
      this.updateLocalPresetField({
        key: 'calculatedScores',
        value: newCalculatedScores
      })
    },
    handleSearchInput: _.debounce(function (event) {
      const searchResult = (this.getFuse.search(event.target.value)).map((result) => {
        return result.item.key
      })
      const result = new Set(searchResult)
      this.updateTextSearch(event.target.value)
      this.setFuzzyResults(result)
      this.showFPMetrics = !(event.target.value.length > 0)
      this.loadingSearch = false
    }, 300),
    debounceSearch (event) {
      this.loadingSearch = true
      this.handleSearchInput(event)
    },
    handleClearSearch () {
    }
  }
}
</script>

<style scoped>
.search-container {
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #EBEFF3;
  background: linear-gradient(90deg, rgba(76, 76, 82, var(--gradient-opacity)) 0%, rgba(52, 52, 59, var(--gradient-opacity)) 100%);
  transition: box-shadow 100ms ease-in-out, --gradient-opacity 100ms ease-in-out;
}
.search-input:focus {
  /* Remove default browser styles */
  outline: none;
  border: none;
  box-shadow: none;

}

.search-input-container:hover, .search-input-container:focus-within {
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #808899; /* neutral-400 */
}

.learn-btn{
background-color: #06070f;
  transition: all 100ms;
}
.learn-btn:hover{
  background-color: rgba(6, 7, 15, 0.92);

}

.checkbox-input{
  border: 1px;
  box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(9, 25, 72, 0.13);
}
</style>
