<template>
  <div>
    <div
      v-if="customGroups.length === 0"
      class="w-full flex items-center justify-center flex-col text-center gap-1 p-4 py-6"
    >
      <BaseText
        class="text-text-muted"
        type="label"
        size="sm"
      >
        No groups defined
      </BaseText>
      <BaseText
        class="text-text-normal w-64"
        size="sm"
      >
        Create a group to start building your report.
      </BaseText>
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <div
        v-for="(group, gpIndex) in customGroups"
        :key="group.id"
      >
        <CustomGroup
          :group="group"
          :ad-info="comparaitiveInfo"
          :current-edit-key="editingFilterKey"
          :existing-names="groupNames"
          @changeCondition="updateGroup"
          @delete="deleteGroup"
          @duplicate="duplicateGroup"
          @toggleEdit="toggleEditAppliedFilter($event)"
          @showBreakdown="$emit('showBreakdown', $event)"
        />
        <!-- Divider -->
        <div
          v-if="gpIndex !== customGroups.length - 1"
          class="border-b border-border-normal"
        />
      </div>
    </div>
    <!-- Footer add -->
    <div
      class="border-b border-border-normal"
    />
    <button
      class="p-1 flex items-center justify-center w-full hover:bg-background-normal transition-colors"
      @click="addGroup"
    >
      <div
        class="p-1 pr-2 flex items-center gap-1"
      >
        <PlusIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Add Group
        </BaseText>
      </div>
    </button>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { createFilterQuery, parseFiltersFromQuery } from '../../../utils/lens/filters/filterQueryUtils'
// Components
import CustomGroup from './CustomGroup.vue'
// Icons
import PlusIcon from '../../globals/Icons/PlusIcon.vue'

export default {
  name: 'ComparaitiveGroupBuilder',
  components: {
    // Components
    CustomGroup,
    // Icons
    PlusIcon
  },
  props: {
    comparaitiveQuery: {
      type: Array,
      default: () => []
    },
    comparaitiveInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      customGroups: [],
      editingFilterKey: null
    }
  },
  computed: {
    groupNames () {
      return this.customGroups.map((group) => { return { id: group.id, name: group.name } })
    }
  },
  watch: {
    comparaitiveQuery: {
      handler (newQuery) {
        this.customGroups = newQuery.map((q) => {
          return {
            id: nanoid(),
            name: q.group_by,
            conditions: parseFiltersFromQuery(q.query)
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    toggleEditAppliedFilter (key) {
      if (this.editingFilterKey !== key) {
        this.editingFilterKey = key
      } else {
        this.editingFilterKey = null
      }
    },
    deleteGroup (deleteId, preventFetch) {
      this.customGroups = this.customGroups.filter((group) => group.id !== deleteId)
      if (!preventFetch) this.emitChanges()
    },
    duplicateGroup (groupId) {
      const groupCopy = this.customGroups.find((group) => group.id === groupId)
      const duplicateGroup = { ...groupCopy, name: `${groupCopy?.name} - Copy`, id: nanoid() }
      this.customGroups.push(duplicateGroup)
      this.emitChanges()
    },
    addGroup () {
      this.customGroups.push({ id: nanoid(), name: '', conditions: [] })
    },
    updateGroup (payload) {
      const updateId = payload.id
      const toUpdate = payload.toUpdate
      this.customGroups = this.customGroups.map((group) => {
        if (group.id === updateId) {
          return {
            ...group,
            ...toUpdate
          }
        }
        return group
      })
      if (!payload.preventFetch) {
        this.emitChanges()
      }
    },
    emitChanges () {
      const query = this.customGroups.map((group, i) => {
        return {
          group_by: group.name,
          query: createFilterQuery(group.conditions)
        }
      })
      this.$emit('update:comparaitiveQuery', query)
    }
  }
}
</script>
<style scoped></style>
